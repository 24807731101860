import API from "../utils/API";

export default class OrganizationModel {

    static getOrganizationById = async (id) => {
        return await API.set(`v1/organization/${id}/public`, "GET")
    }

    static scanQR = async (code) => {
        return await API.set(`/v1/qr/scan/${code}`, "GET")
    }

}