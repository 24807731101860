import {Button, Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Chapter from "../../daos/Chapter";
import BookUserDAO from "../../daos/BookUserDAO";
import {isMobile} from 'react-device-detect';
import CryptoJS from 'crypto-js';
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import {Icon} from "@iconify/react";
import Book from "../../daos/Book";
import Palette from "../../utils/Palette.";
import SettingsPopover from "../reusable/SettingsPopover";
import BookTrackerDAO from "../../daos/BookTrackerDAO";

export default function BookReadPageButtonMode({font, setFont, fontSize, setFontSize, mode, setMode}) {
    const {id, bookId, chapterNum} = useParams();
    const [chapters, setChapters] = useState([]);
    const bottomRef = useRef(null);
    const canvasRef = useRef(null);
    const [nextChapter, setNextChapter] = useState({})
    const [book, setBook] = useState({})
    const [isVisible, setIsVisible] = useState(false);
    const [scrollPercentage, setScrollPercentage] = useState(0);

    let token = sessionStorage.token || localStorage.token;
    let qr_token = sessionStorage.qr_token || localStorage.qr_token;
    const secretKey = '0123456789abcdef0123456789abcdef'

    const {imageURL, primaryColor, secondaryColor, name, favicon, status} = useOrganizationData()
    const decrypt = (text) => {
        try {
            return CryptoJS.AES.decrypt(text, secretKey).toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Error decrypting:', error);
            return null;
        }
    };

    let getNextChapter = async () => {
        try {
            let nextChapterTemp = await Chapter.getChapterByBookIdAndChapterNumber(bookId, parseInt(chapterNum) + 1)
            setNextChapter(nextChapterTemp)
        } catch (e) {
            setNextChapter({})
        }

    }

    const [tracker, setTracker] = useState({})

    const createTracker = async () => {
        try {
            let user_id = sessionStorage.user_id || localStorage.user_id || null
            let createTracker = await BookTrackerDAO.create({
                user_id: parseInt(user_id),
                book_id: parseInt(bookId),
                user_token: qr_token
            })
            sessionStorage.tracker_id = createTracker.id
            localStorage.tracker_id = createTracker.id
            setTracker(createTracker)
        } catch (e) {
            console.log('created tracker err', e)
        }
    }

    const getProgress = async () => {
        try {
            let progress = await BookUserDAO.getByUserIdAndBookId(bookId)
            if (progress.progress <= chapterNum) {
                scrollToPercentage(progress.scroll_progress || 0)
            }
        } catch (e) {
            console.log(e)
        }
    }

    let getData = async () => {
        try {
            let chaptersTemp = [];
            createTracker()

            let result = await Chapter.getChapterByBookIdAndChapterNumber(bookId, chapterNum)
            if (result.error_message === 'MAX_BOOK_CAPACITY_REACHED') {
                navigate('/')
            }
            let bookTemp = await Book.getByID(bookId)
            if (token) {
                getProgress()
            }
            setBook(bookTemp)
            if (result.content) result.content = decrypt(result.content)
            chaptersTemp.push(result)
            setChapters(chaptersTemp);
        } catch (e) {
            console.log('get chapter err', e);
        }
    };

    useEffect(() => {
        getData();
        getNextChapter();
    }, [chapterNum]);

    function scrollToPercentage(percentage) {
        if (percentage < 0 || percentage > 100) {
            console.error('Percentage must be between 0 and 100');
            return;
        }

        const documentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const targetScrollPosition = (percentage / 100) * documentHeight;

        window.scrollTo({
            top: targetScrollPosition,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            try {
                let deleteTracker = BookTrackerDAO.delete(sessionStorage.qr_token || localStorage.qr_token)
            } catch (e) {
                console.log(e)
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            console.log('page has changed!')
            let deleteTracker = BookTrackerDAO.delete(sessionStorage.qr_token || localStorage.qr_token)
            window.removeEventListener('beforeunload', handleBeforeUnload);
            //sessionStorage.removeItem("tracker_id")
        };
    }, []);


    useEffect(() => {
        let scrollPercentageVar = 0
        const handleScroll = () => {
            if (bottomRef.current) {
                const rect = bottomRef.current.getBoundingClientRect();
                const isVisible =
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <=
                    (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth);
                setIsVisible(isVisible);
            }
        };

        const handleScrollPercentage = () => {
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = (scrollTop / docHeight) * 100;
            setScrollPercentage(scrolled);
            scrollPercentageVar = scrolled
        };

        let highestScrollPercentage = 0
        const intervalFunction = async () => {
            if (token) {
                let progress = await BookUserDAO.getByUserIdAndBookId(bookId)
                if (highestScrollPercentage < scrollPercentageVar) {
                    if (progress.progress <= chapterNum) {
                        highestScrollPercentage = scrollPercentageVar
                        let result = await BookUserDAO.saveProgressScroll(bookId, chapterNum, {scroll_progress: scrollPercentageVar})
                    }
                }
            }

        }

        const intervalId = setInterval(intervalFunction, 60000);

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScrollPercentage);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scroll", handleScrollPercentage);
            clearInterval(intervalId)
        };
    }, []);

    useEffect(() => {
        const saveProgress = async () => {
            let token = sessionStorage.getItem("token") || localStorage.getItem("token");
            if (token) {
                try {
                    let result = await BookUserDAO.saveProgress(bookId, chapterNum);
                } catch (e) {
                    console.log(e);
                }
            }
        };

        if (isVisible) {
            saveProgress();
        }
    }, [isVisible]);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        try {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");

            let totalHeight = isMobile ? (window.innerHeight + 200) : window.innerHeight; // Initial y offset
            if (chapters.length > 0) {
                chapters.forEach((chapter) => {
                    if (chapter.content) {
                        context.font = `16px ${font}`;
                        context.fillStyle = mode === "DARK" ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT;
                        const lines = getWrappedLines(context, chapter.content, canvas.width - 20);
                        totalHeight += lines.length * 25 + 40; // Increase line height by adjusting yOffset
                    }
                });
                canvas.height = totalHeight;
                context.clearRect(0, 0, canvas.width, canvas.height);

                let yOffset = 80; // Initial y offset
                chapters.forEach((chapter) => {
                    context.font = !isMobile ? `${3 + fontSize}rem ${font}` : `${2 + fontSize}rem ${font}`; // Font size for chapter number
                    context.fillStyle = mode === "DARK" ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT;
                    context.textAlign = "center";
                    context.fillText(`Bagian ${chapter.chapter_num}`, canvas.width / 2, yOffset);

                    context.font = !isMobile ? `${2.6 + fontSize}rem ${font}` : `${1.2 + fontSize}rem ${font}`; // Font size for chapter title
                    yOffset += 60; // Increase yOffset for next chapter
                    context.textAlign = "center";
                    context.fillText(chapter.title, canvas.width / 2, yOffset);

                    context.font = !isMobile ? `${1.2 + fontSize}rem ${font}` : `${1.2 + fontSize}rem ${font}`; // Font size for chapter content
                    context.fillStyle = mode === "DARK" ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT;
                    yOffset += 60; // Increase yOffset for next line
                    context.textAlign = "left";
                    const lines = getWrappedLines(context, chapter.content, canvas.width - 20);
                    lines.forEach((line) => {
                        context.fillText(line, 10, yOffset);
                        yOffset += 35; // Increase line height by adjusting yOffset
                    });
                    yOffset += 20; // Increase yOffset for next line
                });
            }
        } catch (e) {
            console.log(e)
        }

    }, [chapters, windowSize, font, fontSize, mode]);

    const getWrappedLines = (context, text, maxWidth) => {
        const lines = text.split('\n');
        let wrappedLines = [];
        lines.forEach((line) => {
            const words = line.split(" ");
            let wrappedLine = "";
            words.forEach((word) => {
                const testLine = wrappedLine + word + " ";
                const metrics = context.measureText(testLine);
                const testWidth = metrics.width;
                if (testWidth > maxWidth && wrappedLine !== "") {
                    wrappedLines.push(wrappedLine);
                    wrappedLine = word + " ";
                } else {
                    wrappedLine = testLine;
                }
            });
            wrappedLines.push(wrappedLine);
        });
        return wrappedLines;
    };

    let navigate = useNavigate()

    const handleNextChapter = async () => {
        try {
            setChapters([{...nextChapter, content: decrypt(nextChapter.content)}])
            navigate(`/read/${bookId}/${String(parseInt(chapterNum) + 1)}`)
        } catch (e) {
            console.log(e)
        }
    }

    const handlePreviousChapter = async () => {
        try {
            navigate(`/read/${bookId}/${String(parseInt(chapterNum) - 1)}`)
        } catch (e) {
            console.log(e)
        }
    }

    const handleOnBookTitleClicked = () => {
        navigate(`/book/${bookId}`)

    }

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        } else {
            return text;
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const newSize = {
                width: window.innerWidth,
                height: window.innerHeight
            };
            setWindowSize(newSize);
            console.log(`Window size changed: ${newSize.width}x${newSize.height}`);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [deviceId, setDeviceId] = useState('');

    return (
        <Container style={{
            width: "100%", minHeight: "100vh", marginBottom: "2rem",
            display: 'flex', flexDirection: 'column', justifyContent: 'center'
        }} fluid>
            <Row style={{}}>
            </Row>
            {
                chapters.length > 0 &&
                <div style={{opacity: 0.0001, height: '1px'}} ref={bottomRef}>
                    Load more
                </div>
            }


            <canvas ref={canvasRef} width={window.innerWidth} style={{backgroundColor: ''}}/>
            <Row style={{marginTop: 10, display: 'flex', justifyContent: 'center', backgroundColor: ''}}>
                {
                    !isMobile ?
                        <Col style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'fixed',
                            bottom: 0,
                            border: 'solid 1px black',
                            backgroundColor: '#2f2f2f',
                            color: '#fff',
                            height: 50
                        }}>
                            <div style={{
                                width: '43%',
                                position: "fixed",
                                left: 20,
                                fontSize: '18px',
                                backgroundColor: '',
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                        minWidth: 50,
                                        backgroundColor: '',
                                    }}
                                    onClick={handleOnBookTitleClicked}
                                >
                                    {book.title && truncateText(book.title, 20)}
                                </div>
                                <div style={{
                                    fontSize: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 2,
                                    marginLeft: 10
                                }}>
                                    <Icon icon={'material-symbols:chevron-right'}/>
                                </div>
                                <div style={{
                                    fontSize: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '',
                                    width: '65%',
                                    marginLeft: 10
                                }}>
                                    {chapters.length > 0 && chapters[0].title && truncateText(chapters[0].title, 52)}
                                </div>
                            </div>
                            <Button
                                disabled={parseInt(chapterNum) === 1}
                                style={{
                                    width: 50,
                                    height: 35,
                                    border: 'solid 1px black',
                                    background: '#252525',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={handlePreviousChapter}>
                                <div>
                                    <Icon icon="material-symbols:arrow-left"
                                          color={parseInt(chapterNum) === 1 && '#515151'}
                                          height={40} width={40}/>
                                </div>

                            </Button>
                            <div style={{display: 'flex', justifyContent: 'center', backgroundColor: '', width: '5%'}}>
                                #{chapters.length > 0 && chapters[0].chapter_num}
                            </div>
                            <Button
                                disabled={!nextChapter.content}
                                style={{
                                    width: 50,
                                    height: 35,
                                    border: 'solid 1px black',
                                    background: '#252525',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }} onClick={handleNextChapter}>
                                <div>
                                    <Icon icon="material-symbols:arrow-right" height={40} width={40}
                                          color={!nextChapter.content && '#515151'}/>
                                </div>
                            </Button>
                            <div style={{
                                width: '43%',
                                position: "fixed",
                                right: 50,
                                fontSize: '18px',
                                backgroundColor: '',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    id={'popover1'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                        minWidth: 50,
                                        backgroundColor: 'transparent',
                                        border: '0'
                                    }}
                                >
                                    <Icon icon="material-symbols:settings" height={25} width={25}/>
                                </Button>
                                <SettingsPopover
                                    font={font}
                                    setFont={setFont}
                                    setFontSize={setFontSize}
                                    fontSize={fontSize}
                                    mode={mode}
                                    setMode={setMode}
                                    target={'popover1'}
                                />
                            </div>

                        </Col>
                        :
                        <Col style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            position: 'fixed',
                            bottom: '4rem',
                            border: 'solid 1px black',
                            backgroundColor: '#2f2f2f',
                            height: 50,
                            color: '#fff',

                        }}>
                            <div style={{
                                width: '60%',
                                position: "fixed",
                                left: 10,
                                fontSize: '18px',
                                backgroundColor: '',
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}>
                                <div style={{cursor: 'pointer'}} onClick={handleOnBookTitleClicked}>
                                    <Icon icon="material-symbols:list"
                                          height={30} width={30}/>
                                </div>
                                <div style={{
                                    fontSize: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '',
                                    marginLeft: 10
                                }}>
                                    {chapters.length > 0 && truncateText(chapters[0].title, 22)}
                                </div>
                            </div>
                            <Button
                                disabled={parseInt(chapterNum) === 1}
                                style={{
                                    width: 50,
                                    height: 35,
                                    border: 'solid 1px black',
                                    background: '#252525',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={handlePreviousChapter}>
                                <div>
                                    <Icon icon="material-symbols:arrow-left"
                                          color={parseInt(chapterNum) === 1 && '#515151'}
                                          height={40} width={40}/>
                                </div>

                            </Button>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: '',
                                width: '5%',
                                marginLeft: 10,
                                marginRight: 10
                            }}>
                                #{chapters.length > 0 && chapters[0].chapter_num}
                            </div>
                            <Button
                                disabled={!nextChapter.content}
                                style={{
                                    width: 50,
                                    height: 35,
                                    border: 'solid 1px black',
                                    background: '#252525',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer'
                                }} onClick={handleNextChapter}>
                                <div>
                                    <Icon icon="material-symbols:arrow-right" height={40} width={40}
                                          color={!nextChapter.content && '#515151'}/>
                                </div>
                            </Button>
                        </Col>
                }
            </Row>

        </Container>
    );
}
