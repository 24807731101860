import {Container, Form, Label, FormGroup, Input, Button, Row} from "reactstrap"
import {useOrganizationData} from "../../utils/OrganizationDataProvider"
import {Link, useNavigate} from "react-router-dom"
import {Image} from "react-bootstrap"
import MainButton from "../reusable/MainButton"
import UsersDAO from "../../daos/UsersDAO";
import {useEffect, useState} from "react";
import swal from "../reusable/CustomSweetAlert";

const LoginPage = () => {

    const navigate = useNavigate()
    const {lightingMode, lightingBackground, lightingText, imageURL} = useOrganizationData()

    useEffect(() => {
        if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
            navigate("/profile")
        }
    }, []);

    const handleSubmit = async () => {
        try {
            let result = await UsersDAO.login({email: email, password: password})
            console.log('result', result)
            // if (result.error_message === "EMAIL_NOT_FOUND") return await swal.fire({
            //     title: 'Login tidak berhasil!',
            //     text: "Email atau password salah!",
            //     icon: 'error',
            //     confirmButtonText: 'Okay'
            // })
            localStorage.super_token = result.token;
            localStorage.token = result.token;
            localStorage.email = result.email;
            localStorage.role = result.role;
            localStorage.user_id = result.id;

            sessionStorage.super_token = result.token;
            sessionStorage.token = result.token;
            sessionStorage.email = result.email;
            sessionStorage.role = result.role;
            sessionStorage.user_id = result.id;


            navigate("/")
        } catch (e) {
            if (e) await swal.fire({
                title: 'Login tidak berhasil!',
                text: "Email atau password salah!",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
            console.log(e)
        }
    }

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    return (
        <div style={{backgroundColor: lightingBackground, color: lightingText, minHeight: '100vh'}}>
            <Container className="pt-3 pb-3">
                <div style={{gap: "2rem", fontSize: "0.9rem"}}>
                    <div style={{fontSize: "1.75rem", marginTop: "2rem"}}>
                        <b>Selamat Datang</b>
                    </div>
                    <div style={{fontSize: "0.9rem", marginTop: "1rem",}}>Log in di bawah untuk akses akunmu</div>
                    <Form style={{marginTop: "2rem", fontSize: "0.9rem"}}>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input onChange={(e) => setEmail(e.target.value)} placeholder="Masukkan alamat email"
                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") handleSubmit()
                                   }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input onChange={(e) => setPassword(e.target.value)} type="password"
                                   placeholder="Masukkan password"
                                   onKeyDown={(e) => {
                                       if (e.key === "Enter") handleSubmit()
                                   }}
                            />
                        </FormGroup>
                    </Form>
                    <Container style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
                        <Row>
                            <Link to={'/forgot-password'}>
                                <Button color="link" style={{
                                    color: lightingText,
                                    textDecoration: "none",
                                    width: "fit-content",
                                    padding: 0,
                                    fontSize: "0.9rem"
                                }}>
                                    Lupa password?
                                </Button>
                            </Link>
                        </Row>
                        <Row><MainButton onClick={handleSubmit} color={"PRIMARY"} text={"Log in"}/></Row>
                        <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Belum punya
                            akun?&nbsp;<Button color="link" onClick={() => navigate("/signup")} style={{
                                color: lightingText,
                                textDecoration: "none",
                                width: "fit-content",
                                padding: 0
                            }}><b>Sign up</b></Button></Row>
                        {/* <Row>
                            <Link to={'/forgot-password'}>
                                <Button color="link" style={{
                                    color: lightingText,
                                    textDecoration: "none",
                                    width: "fit-content",
                                    padding: 0,
                                    fontSize: "0.9rem"
                                }}>Lupa password?</Button>
                            </Link>
                        </Row> */}
                    </Container>
                </div>
            </Container>
        </div>
    )
}

export default LoginPage