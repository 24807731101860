import { Button, Card, CardImg, Col, Container, Image, Row, InputGroup, Form } from "react-bootstrap";
import Book from "../../daos/Book";
import CardThumb from "../reusable/CardThumb";
import Palette from "../../utils/Palette.";
import MainButton from "../reusable/MainButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useOrganizationData } from "../../utils/OrganizationDataProvider";
export default function SearchPage() {
  const location = useLocation()
  const [books, setBooks] = useState([])
  const [searchKeyword, setSearchKeyword] = useState(null)
  const [formattedString, setFormattedString] = useState(null)
  const { lightingMode, lightingBackground, lightingText, primaryColor, secondaryColor } = useOrganizationData()

  const navigate = useNavigate()
  const search = async (keyword) => {
    try{
      console.log(keyword)
      let res = await Book.searchRestricted({ keyword: decodeURI(keyword) })
      console.log('search result', res)
      setBooks(res)
    }catch (e) {
      console.log('search result err', e)
    }

  }
  useEffect(() => {
    let str = location.pathname.split("/")[2]
    if(str){
      search(str)
      setFormattedString(str.replaceAll("%20", " "))
    }


  }, [location.pathname]);

  return (
    <>
      <Container /* fluid */>
        <Row>
          <InputGroup size="lg" style={{ width: "100%", marginTop: "2rem" }}>
          <InputGroup.Text style={{ borderColor: primaryColor, color: primaryColor, }}><Icon icon="material-symbols:search" onClick={() => navigate("/search/" + searchKeyword)}/></InputGroup.Text>
            <Form.Control
              placeholder="Search"
              onChange={(e) => setSearchKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  //console.log(searchKeyword)
                  navigate("/search/" + searchKeyword)
                }
              }}
              style={{ borderColor: primaryColor, color: 'black' }} />
          </InputGroup>
        </Row>
        {/* <Row className={'mt-5'}>
          <h1 style={{ color: Palette.TEXT_MAIN }}>Your Site Name</h1>
        </Row>
        <Row className={'py-2'}>
          <Col lg={5}>
            <Card>
              <CardImg src={'/images/banner_example.jpeg'}></CardImg>
            </Card>
          </Col>
          <Col lg={6}>
            <p style={{ color: Palette.TEXT_MAIN }}>
              {Book.BooksHighlight.description}
            </p>
            <Row>
              <div className="d-grid gap-2">
                <MainButton icon={'mi:book'} color={'PRIMARY'} text={'Baca'} />
                <MainButton icon={'ic:baseline-plus'} color={'SECONDARY'} text={'Daftar Saya'} />
              </div>

            </Row>
          </Col>
        </Row> */}
        {location.pathname.split("/")[2] && <Container className={'p-3'}>
          <Row>
            <h2 style={{}} className={'mb-3 mt-3'}>Hasil Pencarian Untuk: {formattedString}</h2>
          </Row>
          <Row>
            {
              books.length>0 ?
              books.map((data) => {
                return (
                  <>
                    <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                      <CardThumb
                        cover={data.cover}
                        url={`/book/${data?.id}`}
                        title={data.title}
                        author={data.author}
                      />
                    </Col>
                  </>
                )
              })
              :
              <div>Hasil Pencarian Tidak Ditemukan</div>
            }
          </Row>
        </Container>
        }
      </Container>

    </>
  )
}