import {Container} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useState} from "react";
import MainButton from "../reusable/MainButton";
import UsersDAO from "../../daos/UsersDAO";
import {useNavigate, useParams} from "react-router-dom";
import swal from "../reusable/CustomSweetAlert";

export default function ResetPassword() {

    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const navigate = useNavigate()
    const queryParameters = new URLSearchParams(window.location.search)
    const email = queryParameters.get("email")
    const token = queryParameters.get("token")

    const handleSubmit = async () => {
        try {
            if (!password || !confirmPassword) {
                return await swal.fire({
                    text: "Password dan Konfirmasi Password tidak boleh kosong!",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }

            if (password !== confirmPassword) {
                return await swal.fire({
                    text: "Password dan Konfirmasi Password tidak sesuai!",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }

            let result = await UsersDAO.sendResetPasword({
                email: email,
                token: token,
                new_password: password
            })
            if (!result?.success) {
                return await swal.fire({
                    text: "Gagal melakukan reset password",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }

            return await swal.fire({
                title: "Berhasil",
                text: "Silahkan untuk melakukan login kembali!",
                icon: 'success',
                confirmButtonText: 'Okay'
            }).then(() => {
                navigate('/login')
            })

        } catch (e) {
            return await swal.fire({
                text: "Terjadi kesalahan pada server silahkan coba kembali nanti!",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }
    return (
        <>
            <Container className="pt-3 pb-3">
                <div style={{gap: "2rem", fontSize: "0.9rem"}}>
                    <div style={{fontSize: "1.75rem", marginTop: "2rem"}}>
                        <b>Lupa Password</b>
                    </div>

                    <div style={{fontSize: "0.9rem", marginTop: "1rem",}}>Masukan Password Baru Anda</div>
                    <Form style={{marginTop: "2rem", fontSize: "0.9rem"}}>
                        <FormGroup>
                            <Label>Password Baru</Label>
                            <Input type={'password'} onChange={(e) => setPassword(e.target.value)}
                                   placeholder="Masukkan Password Baru"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Konfirmasi Password Baru</Label>
                            <Input type={'password'} onChange={(e) => setConfirmPassword(e.target.value)}
                                   placeholder="Masukkan Konfirmasi Password Baru"/>
                        </FormGroup>
                    </Form>
                    <Row><MainButton onClick={() => {
                        handleSubmit()
                    }} color={"PRIMARY"} text={"Kirim Verifikasi"}/></Row>
                </div>
            </Container>
        </>
    )
}