export default class Palette {
    static THEME_BACKGROUND = "#0A0A0A"
    static THEME_PRIMARY = "#FEBE10"
    static THEME_PRIMARY_DARK = "#cb980d"
    static THEME_SECONDARY = "#404040"
    static TEXT_MAIN = '#FFFFFF'
    static TEXT_MAIN_DARK = '#C2C2C2'
    static TEXT_SECONDARY = '#0A0A0A'

    //COLORS
    static THEME_BLACK = "#000000"
    static THEME_GRAY = "#9E9E9E"
    static THEME_NEUTRAL_GRAY = "#E0E0E0"
    static THEME_DARK_GRAY = "#404040"
    static THEME_WHITE = "#FFFFFF"

    //COLORS V2
    static DARK_MODE_TEXT = "#FFFFFF"
    static DARK_MODE_GRAY = "#C2C2C2"
    static DARK_MODE_INVERT_TEXT = "#000000"
    static DARK_MODE_BACKGROUND = "#000000"

    static LIGHT_MODE_TEXT = "#000000"
    static LIGHT_MODE_GRAY = "#C2C2C2"
    static LIGHT_MODE_GRAY = "#FFFFFF"
    static LIGHT_MODE_BACKGROUND = "#FFFFFF"


}