import { Icon } from "@iconify/react"
import { Col, Row } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { useOrganizationData } from "../../utils/OrganizationDataProvider"
const Footer = ({ style }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { imageURL, primaryColor, secondaryColor, lightingText } = useOrganizationData()

  const checkPage = (buttonLocation) => {
    console.log(location.pathname.split("/")[1])
    if (buttonLocation == "Baca") {
      if (location.pathname.split("/")[1] == "book" ||
        location.pathname.split("/")[1] == "read" ||
        location.pathname.split("/")[1] == ""
      ) {
        return primaryColor
      }
      return lightingText
    }
    if (buttonLocation == "Cari") {
      if (location.pathname.split("/")[1] == "search") {
        return primaryColor
      }
      return lightingText
    }
    if (buttonLocation == "Akun") {
      if (location.pathname.split("/")[1] == "profile" || location.pathname.split("/")[1] == "login") {
        return primaryColor
      }
      return lightingText
    }
  }

  return (
    <div style={{ ...style, height: "4rem", width: "100%", position: "fixed", bottom: 0, boxShadow: "0rem 0.2rem 0.2rem 0.2rem black" }}>
      <Row style={{ height: "100%" }}>
        <Col xs={4} style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: checkPage("Baca") }} onClick={() => navigate('/')}>
          <Icon icon={'mi:book'} />
          <div>Baca</div>
        </Col>
        <Col xs={4} style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: checkPage("Cari") }} onClick={() => navigate('/search')}>
          <Icon icon="material-symbols:search" />
          <div>Cari</div>
        </Col>
        <Col xs={4} style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: checkPage("Akun") }} onClick={() => navigate('/profile')}>
          <Icon icon="mdi:account" />
          <div>Akun</div>
        </Col>
      </Row>
    </div>
  )
}

export default Footer