import {Card, CardImg, Col, Container, Row} from "react-bootstrap";
import Book from "../../daos/Book";
import Category from "../../daos/Category";
import CardThumb from "../reusable/CardThumb";
import MainButton from "../reusable/MainButton";
import {useEffect, useState} from "react";
import {Link, useOutletContext} from "react-router-dom";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookUserDAO from "../../daos/BookUserDAO";
import swal from "../reusable/CustomSweetAlert";
import {isMobile} from "react-device-detect";

export default function HomePage() {
  const { lightingMode, lightingBackground, lightingText } = useOrganizationData()
  let token = sessionStorage.getItem('token') || localStorage.getItem('token')


  let sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    //slidesToScroll: 3,
    autoplay: true
  }
  const [booksNew, setBooksNew] = useState([])
  const [categories, setCategories] = useState([])

  const [windowWidth, setWindowWidth] = useOutletContext()

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  const initialize = async () => {
    try {
      const result = await Book.getAllByCode(sessionStorage.getItem('code') || localStorage.getItem('code'))
      console.log('result', result)
      //const result = await Book.getAll()

      const categoriesTemp = await Category.getAll()

      const bookCategories = result.map(data => data.book_category.map(data2 => {return data2.categories.name}))

      for(let i = 0; i < result.length; i++) {
        result[i].categories = bookCategories[i]
      }

      setBooksNew(result)
      setCategories(categoriesTemp.filter(data => data.show_homepage == true))

    } catch (e) {

    }
  }

  const [favoriteBookIds, setFavoriteBookIds] = useState([])

  const getHistory = async () => {
    try{
      if(token){
        let result = await BookUserDAO.getHistory()
        let favoriteBookIdsTemp = result.filter((res) => res.favorite).map((re) => re.book_id)

        setFavoriteBookIds(favoriteBookIdsTemp)
        console.log('get history', favoriteBookIds)
      }
    }catch (e) {

    }
  }

  useEffect(() => {
    getHistory()
  }, [booksNew]);

  useEffect(() => {
    initialize()
  }, []);

  const handleAddToFavorite = async (id) => {
    try{
      if(!token) return await swal.fire({
        title: 'Login terlebih dahulu!',
        text: "Harap login sebelum tambah buku ke daftar saya!",
        icon: 'error',
        confirmButtonText: 'Okay'
      })

      let result = await BookUserDAO.setFavorite(id)
      getHistory()
      console.log('result favorite', result)
    }catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Container /* fluid */ className={'p-3'}>
        <Row className={'p-3'}>
          <Col sm={3}>
            <Card>
              <CardImg src={booksNew[booksNew.length - 1]?.cover} />
              {windowWidth <= 576 && <Col style={{ position: "absolute", bottom: 0, padding: 15, backgroundColor: "rgb(255,255,255,0.5)" }}>
                {/* <h2> {Book.BooksHighlight.title}</h2>
                <h4>Oleh Sutardji Calzoum Bachri</h4> */}
                <p style={{}}>
                  {!isMobile ? booksNew[booksNew.length - 1]?.description : booksNew[booksNew.length - 1]?.description && truncateText(booksNew[booksNew.length - 1]?.description, 150 )}
                </p>
                <Row style={{ padding: "0rem 0.5rem 0.5rem 0.5rem" }}>
                  <Col xs={6}>
                    <Link style={{ textDecoration: 'none' }} to={`/book/${booksNew[booksNew.length-1]?.id}`} className={'w-100 d-grid'}>
                      <MainButton icon={'mi:book'} color={'PRIMARY'} text={'Baca'} />
                    </Link>
                  </Col>
                  <Col xs={6}>
                    <MainButton onClick={()=>handleAddToFavorite(booksNew[booksNew.length - 1]?.id)} icon={ booksNew[booksNew.length-1] && favoriteBookIds.includes(booksNew[booksNew.length-1].id) ? 'ic:baseline-check' : 'ic:baseline-plus'} color={'SECONDARY'} text={'Daftar Saya'} />
                  </Col>
                </Row>
              </Col>}
            </Card>
          </Col>
          {windowWidth > 576 && <Col sm={9} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div>
            <h2> {booksNew[booksNew.length - 1]?.title}</h2>
            <h4>Oleh {booksNew[booksNew.length - 1]?.author}</h4>
            <p style={{}}>
              {booksNew[booksNew.length - 1]?.description}
            </p>
            </div>
            
            <Row style={{position: "relative", bottom: 0 }}>
              <div className="d-grid gap-2">
                <Link style={{ textDecoration: 'none' }} to={`/book/${booksNew[booksNew.length-1]?.id}`} className={'w-100 d-grid'}>
                  <MainButton icon={'mi:book'} color={'PRIMARY'} text={'Baca'} />
                </Link>
                <MainButton onClick={()=>handleAddToFavorite(booksNew[booksNew.length - 1]?.id)} icon={ booksNew[booksNew.length-1] && favoriteBookIds.includes(booksNew[booksNew.length-1].id) ? 'ic:baseline-check' : 'ic:baseline-plus'} color={'SECONDARY'} text={'Daftar Saya'} />
              </div>

            </Row>
          </Col>}
        </Row>
        <Container className={'p-3'}>
          <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
            <h2 style={{}} className={'homepage-section-title '+`${windowWidth < 576 ? 'mobile' : 'normal'}`}>Baru Ditambahkan</h2>
            <Link to={`/books/recent/0`}><p className={'homepage-section-more '+`${windowWidth < 576 ? 'mobile' : 'normal'}`}>Lihat Semua</p></Link>
          </div>
          {/* <Row className="slider-container">
            <Slider {...sliderSettings} swipe swipeToSlide style={{colGap: "2rem"}}>
              {
                [...booksNew,...booksNew,...booksNew]?.map((data) => {
                  return (
                    <Col style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                      <CardThumb
                        cover={data.cover}
                        url={`/book/${data?.id}`}
                      />
                    </Col>
                  )
                })
              }
            </Slider>
          </Row> */}
          <Row>
            {
              booksNew?.sort((a, b) => b.id - a.id).slice(0, 6).map((data, idx) => {
                if (idx < 3 || windowWidth > 576) return (
                  <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                    <CardThumb
                      cover={data.cover}
                      url={`/book/${data?.id}`}
                      title={data.title}
                      author={data.author}
                    />
                  </Col>
                )
              })
            }
          </Row>
          
          {categories && categories.map((cat)  => {
            let filteredBooks =  booksNew.filter(data => data.categories.includes(cat.name))
            console.log('filteredBooks', filteredBooks)
            return (
              <>
                {
                  filteredBooks.length > 0 &&
                    <>
                      <Row>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                          <h2 style={{}} className={'homepage-section-title '+`${windowWidth < 576 ? 'mobile' : 'normal'}`}>{cat.name}</h2>
                          <Link to={`/books/${cat.name}/${cat.id}`}><p className={'homepage-section-more '+`${windowWidth < 576 ? 'mobile' : 'normal'}`}>Lihat Semua</p></Link>
                        </div>

                      </Row>
                      <Row>
                        {
                          /* result.map(data => data.book_category.filter(data2 => data2.categories.name == "Novel")) */
                          booksNew.filter(data => data.categories.includes(cat.name)).map((data, idx) => {
                            if (idx < 3 || windowWidth > 576) return (
                                <>
                                  <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                                    <CardThumb
                                        cover={data.cover}
                                        url={'/book/' + data.id}
                                        title={data.title}
                                        author={data.author}
                                    />
                                  </Col>
                                </>
                            )
                          })
                        }
                      </Row>
                    </>
                }
              </> 
            )})}

          {/* <Row>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <h2 style={{}} className={'homepage-section-title '+`${windowWidth <576? 'mobile' : 'normal'}`}>Trending Saat Ini 🔥</h2>
              <p className={'homepage-section-more '+`${windowWidth <576? 'mobile' : 'normal'}`}>Lihat Semua</p>
            </div>

          </Row>
          <Row>
            {
              Book.BooksDummy?.map((data, idx) => {
                if (idx < 3 || windowWidth> 576) return (
                  <>
                    <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                      <CardThumb
                        cover={data.cover}
                        url={'/book/1'}
                        title={data.title}
                      author={data.author}
                      />
                    </Col>
                  </>
                )
              })
            }
          </Row>
          <Row>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <h2 style={{}} className={'homepage-section-title '+`${windowWidth <576? 'mobile' : 'normal'}`}>Pilihan Teratas untuk John Doe</h2>
              <p className={'homepage-section-more '+`${windowWidth <576? 'mobile' : 'normal'}`}>Lihat Semua</p>
            </div>
          </Row>
          <Row>
            {
              Book.BooksDummy?.map((data, idx) => {
                if (idx < 3 || windowWidth> 576) return (
                  <>
                    <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                      <CardThumb
                        cover={data.cover}
                        url={'/book/1'}
                        title={data.title}
                        author={data.author}
                      />
                    </Col>
                  </>
                )
              })
            }
          </Row>
          <Row>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <h2 style={{}} className={'homepage-section-title '+`${windowWidth <576? 'mobile' : 'normal'}`}>Bersejarah</h2>
              <p className={'homepage-section-more '+`${windowWidth <576? 'mobile' : 'normal'}`}>Lihat Semua</p>
            </div>
          </Row>
          <Row>
            {
              Book.BooksDummy?.map((data, idx) => {
                if (idx < 3 || windowWidth> 576) return (
                  <>
                    <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                      <CardThumb
                        cover={data.cover}
                        url={'/book/1'}
                        title={data.title}
                      author={data.author}
                      />
                    </Col>
                  </>
                )
              })
            }
          </Row> */}
        </Container>
      </Container>

    </>
  )
}