import {Container} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useState} from "react";
import MainButton from "../reusable/MainButton";
import UsersDAO from "../../daos/UsersDAO";
import {useNavigate} from "react-router-dom";
import swal from "../reusable/CustomSweetAlert";

export default function ForgotPassword() {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            if (!email) {
                setIsLoading(false)
                return await swal.fire({
                    text: "Email tidak boleh kosong!",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }

            let result = await UsersDAO.sendMailForget({email: email})
            if (!result?.success) {
                setIsLoading(false)
                return await swal.fire({
                    text: "Pengguna tidak ditemukan",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }

            setIsLoading(false)

            return await swal.fire({
                title: "Berhasil",
                text: "Harap cek email untuk melakukan reset password!",
                icon: 'success',
                confirmButtonText: 'Okay'
            }).then(() => {
                navigate('/')
            })
        } catch (e) {

            setIsLoading(false)

            return await swal.fire({
                text: "Terjadi kesalahan pada server silahkan coba kembali nanti!",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }
    return (
        <>
            <Container className="pt-3 pb-3">
                <div style={{gap: "2rem", fontSize: "0.9rem"}}>
                    <div style={{fontSize: "1.75rem", marginTop: "2rem"}}>
                        <b>Lupa Password</b>
                    </div>

                    <div style={{fontSize: "0.9rem", marginTop: "1rem",}}>Masukan Email Pemulihan</div>
                    <div style={{marginTop: "2rem", fontSize: "0.9rem"}}>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input onChange={(e) => setEmail(e.target.value)} placeholder="Masukkan alamat email"/>
                        </FormGroup>

                    </div>
                    <Row><MainButton disabled={true} onClick={() => {
                        handleSubmit()
                    }} color={"PRIMARY"} text={"Kirim Verifikasi"} mainButtonProps={{disabled : isLoading}}/></Row>
                </div>
            </Container>
        </>
    )
}