import {Col, Container, Row} from "react-bootstrap";
import Book from "../../daos/Book";
import Category from "../../daos/Category";
import CardThumb from "../reusable/CardThumb";
import {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BookListByCategory() {
  const { lightingMode, lightingBackground, lightingText } = useOrganizationData()

  let sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    //slidesToScroll: 3,
    autoplay: true
  }

  const { cat, catId } = useParams()

  const [booksNew, setBooksNew] = useState([])
  const [categories, setCategories] = useState([])

  const [windowWidth, setWindowWidth] = useOutletContext()

  const initialize = async () => {
    try {
      let result =  await Book.getAllByCodeAndCategory(sessionStorage.getItem('code') || localStorage.getItem('code'), catId)

      if(cat === 'recent'){
        let temp = await Book.getAllByCode(sessionStorage.getItem('code') || localStorage.getItem('code'))
        setBooksNew(temp)
      }else{
        const categoriesTemp = await Category.getAll()
        console.log('result', result)

        const bookCategories = result.map(data => data.book_category.map(data2 => {return data2.categories.name}))

        for(let i = 0; i < result.length; i++) {
          result[i].categories = bookCategories[i]
        }

        console.log("aaa", result)

        setBooksNew(result)
        setCategories(categoriesTemp.filter(data => data.show_homepage == true))
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    initialize()
  }, []);

  return (
    <>
      <Container /* fluid */ className={'p-3'}>
        <Container className={'p-3'}>
          
          {
            cat === "recent" ?
            <>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                    <h2 style={{}} className={'homepage-section-title '+`${windowWidth < 576 ? 'mobile' : 'normal'}`}>Baru Ditambahkan</h2>
                </div>
                <Row>
                    {
                    booksNew?.sort((a, b) => b.id - a.id).map((data, idx) => {
                        return (
                        <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                            <CardThumb
                            cover={data.cover}
                            url={`/book/${data?.id}`}
                            title={data.title}
                            author={data.author}
                            />
                        </Col>
                        )
                    })
                    }
                </Row>
            </>
          :
            <>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                    <h2 style={{}} className={'homepage-section-title '+`${windowWidth < 576 ? 'mobile' : 'normal'}`}>{cat}</h2>
                </div>
                <Row>
                    {
                    /* result.map(data => data.book_category.filter(data2 => data2.categories.name == "Novel")) */
                    booksNew.filter(data => data?.categories.includes(cat)).map((data, idx) => {
                        return (
                        <>
                            <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                            <CardThumb
                                cover={data.cover}
                                url={'/book/' + data.id}
                                title={data.title}
                            author={data.author}
                            />
                            </Col>
                        </>
                        )
                    })
                    }
                </Row>
            </>
          }
        </Container>
      </Container>

    </>
  )
}