import API from "../utils/API";

export default class Chapter {
    static getAll = async () => {
        return await API.set("v1/chapters", "GET")
    }

    /* static search = async (body) => {
        return await API.set("v1/book/search", "POST", body)
    } */

    static getByID = async (id) => {
        return await API.set("v1/chapter/" + id, "GET")
    }

    /* static getChapters = async (id) => {
        return await API.set("v1/chapter/book/" + id, "GET")
    } */

    static getChapterByBookIdAndChapterNumber = async (book_id, chapter_num) => {
        return await API.set(`v1/chapter/${book_id}/${chapter_num}/user`)
    }

}