import { Button, ButtonGroup } from "react-bootstrap";
import Palette from "../../utils/Palette.";
import { Icon } from "@iconify/react";
import { useOrganizationData } from "../../utils/OrganizationDataProvider";

export default function MainButton({
    color,
    variant,
    style,
    onClick,
    text,
    icon,
    secondary_button,
    mainButtonProps={},
    secondaryButtonProps={},
}) {

    const { lightingMode, primaryColor, secondaryColor } = useOrganizationData()

    const buttonPrimary = {
        background: primaryColor,
        borderColor: primaryColor,
        color: "#ffffff"
        // color: lightingMode === "DARK" ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT
    }

    const buttonSecondary = {
        background: secondaryColor,
        borderColor: secondaryColor,
        color: "#ffffff"
        // color: lightingMode === "DARK" ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT
    }

    const getColors = (type) => {
        let objColor = {};
        switch (type) {
            case 'PRIMARY':
                objColor = buttonPrimary
                break
            case 'SECONDARY':
                objColor = buttonSecondary
                break
            default:
                objColor = {}
        }
        return objColor
    }

    return (
        <>
            {
                !secondary_button &&
                <Button onClick={onClick} style={{ ...getColors(color), ...style }} className={'main-button'} variant={variant}  {...mainButtonProps} >
                    {
                        icon ? <span style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><Icon icon={icon} ></Icon>&nbsp;{text}</span> : `${text}`
                    }
                </Button>
            }
            {
                secondary_button &&
                <ButtonGroup>
                    <Button onClick={onClick} style={{ ...getColors(color), ...style }}  variant={variant} {...mainButtonProps}>
                        {
                            icon ? <span style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><Icon icon={icon} ></Icon>&nbsp;{text}</span> : `${text}`
                        }
                    </Button>
                    <Button onClick={secondary_button.onClick} style={{ ...getColors(secondary_button.color), ...secondary_button.style }} variant={variant} {...secondaryButtonProps}>
                        {
                            icon ? <span style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><Icon icon={secondary_button.icon} ></Icon>&nbsp;{secondary_button.text}</span> : `${secondary_button.text}`
                        }

                    </Button>
                </ButtonGroup>
            }

        </>
    )
}