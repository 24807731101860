import {ButtonGroup, FormGroup, Input, Label, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";
import {Button, ToggleButton} from "react-bootstrap";
import {Icon} from "@iconify/react";
import React from "react";

export default function SettingsPopover({mode, setMode, font, setFont, fontSize, setFontSize, target}) {


    return(
        <UncontrolledPopover
            placement="top"
            target={target}
            trigger="click"
        >
            <PopoverHeader>
                Settings
            </PopoverHeader>
            <PopoverBody>
                <FormGroup onChange={e => setFont(e.target.value)}>
                    <Label for="exampleSelect">
                        Font
                    </Label>
                    <Input
                        value={font}
                        id="exampleSelect"
                        name="select"
                        type="select"
                    >
                        <option>
                            Arial
                        </option>
                        <option>
                            Times New Roman
                        </option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>
                        Font Size
                    </Label> <br/>
                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                let fontSizeTemp = fontSize - 0.25
                                setFontSize(fontSizeTemp)
                            }}
                            style={{backgroundColor: '#6c757d', border: 'none'}}>
                            -
                        </Button>
                        <Button style={{backgroundColor: '#6c757d', border: 'none'}}>
                            {fontSize > 0 && '+'}{fontSize}
                        </Button>
                        <Button
                            onClick={() => {
                                let fontSizeTemp = fontSize + 0.25
                                setFontSize(fontSizeTemp)
                            }}
                            style={{backgroundColor: '#6c757d', border: 'none'}}>
                            +
                        </Button>
                    </ButtonGroup>
                </FormGroup>
                <FormGroup>
                    <Label>
                        Mode
                    </Label>
                    <br/>
                    <ButtonGroup

                        className="mb-2">
                        <ToggleButton
                            id="toggle-check"
                            type="checkbox"
                            variant="secondary"
                            checked={mode === 'LIGHT'}
                            value="LIGHT"
                            onClick={()=> setMode("LIGHT")}

                        >
                            <Icon icon={'mi:sun'} style={{marginRight: 5}} />

                            Light
                        </ToggleButton>
                        <ToggleButton
                            id="toggle-check"
                            type="checkbox"
                            variant="secondary"
                            checked={mode === 'DARK'}
                            value="DARK"
                            onClick={()=> setMode("DARK")}
                        >
                            <Icon icon={'mi:moon'} style={{marginRight: 5}} />

                            Dark
                        </ToggleButton>
                    </ButtonGroup>
                </FormGroup>
            </PopoverBody>
        </UncontrolledPopover>
    )
}