import { Container, Form, Label, FormGroup, Input, Button, Row } from "reactstrap"
import { useOrganizationData } from "../../utils/OrganizationDataProvider"
import {Link, useNavigate} from "react-router-dom"
import { Image } from "react-bootstrap"
import MainButton from "../reusable/MainButton"
import { useEffect, useState } from "react"
const NotFound = () => {

  const { status } = useOrganizationData()
  let navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, []);

  return (
    <div style={{ backgroundColor: "#000000", color: "#FFFFFF", minHeight: '100vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
      Halaman tidak ada!
    </div>
  )
}

export default NotFound