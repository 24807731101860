import {Card, CardImg} from "react-bootstrap";
import Palette from "../../utils/Palette.";
import {Link} from "react-router-dom";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";

export default function CardThumb({cover, url,title, author, progress}) {
    const {primaryColor} = useOrganizationData()

    return (
        <>
            <Card style={{
                position: 'relative',
                border: "1px solid black",
                //boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
                overflow: 'hidden', // Ensures the border doesn't affect the layout
            }}>
                <Link to={`${url}`}>
                    <CardImg src={cover}></CardImg>
                </Link>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: `${progress}%`,
                    height: '5%', // Adjust this percentage as needed
                    backgroundColor: progress ? primaryColor : 'transparent',
                    borderBottom: `1px solid ${Palette.THEME_BLACK}`,
                }} />
            </Card>
            <p>{title}</p>
            <p>{author}</p>
        </>
    )
}