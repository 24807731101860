import API from "../utils/API";

export default class BookTrackerDAO {
    static create = async (body) => {
        return await API.set('v1/book-tracker', 'POST', body)
    }

    static delete = async (id) => {
        console.log('deleted tracker', `v1/book-tracker-token/${id}`)
        return await API.set(`v1/book-tracker-token/${id}`, 'DELETE')
    }
}