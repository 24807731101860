import {Outlet, useLocation, useNavigate} from "react-router-dom"
import Header from "./Header";
import {useEffect, useState} from "react";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import Palette from "../../utils/Palette.";
import Footer from "./Footer";

export default function MenuLayout({font, setFont, fontSize, setFontSize, setMode, mode}) {
    const [openSidebar, setOpenSidebar] = useState(false)

    const navigate = useNavigate()

    const { lightingMode, lightingBackground, lightingText } = useOrganizationData()

    const [windowWidth, setWindowWidth] = useState()

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const handleRouteChange = () => {
        const isLoginRequired = sessionStorage.login_required || localStorage.login_required
        const token = sessionStorage.token || localStorage.token
        if(isLoginRequired === "true"){
            if(!token && location.pathname !== '/login'){
                navigate('/login')
            }
        }
        console.log('Route changed!', location, isLoginRequired);
    };

    // Hook to detect route changes
    const location = useLocation();
    useEffect(() => {
        handleRouteChange();
    }, [location]);

    return <div className="d-flex flex-column" style={{ height: "100vh", }}>
        <Header windowWidth={windowWidth} font={font} setFont={setFont} setFontSize={setFontSize} fontSize={fontSize} setMode={setMode} mode={mode}/>
        {/* <Sidebar collapseOpen={openSidebar} setCollapseOpen={setOpenSidebar}/> */}
        <div style={{
            flex: 1,
            paddingBottom: windowWidth>576? 0 : "2rem",
            background: mode  ==="DARK" ? Palette.DARK_MODE_BACKGROUND : Palette.LIGHT_MODE_BACKGROUND,
            // color : "lightingMode ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT",
            color: mode ==="DARK" ? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT ,
        }}>
            <Outlet context={[windowWidth, setWindowWidth]} />
        </div>
        {windowWidth <= 576 &&
            <Footer style={{
                background: lightingBackground,
                color: lightingText
            }} />
        }
    </div>
}