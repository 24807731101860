import {Container, Form, Label, FormGroup, Input, Button, Row} from "reactstrap"
import {useOrganizationData} from "../../utils/OrganizationDataProvider"
import {Link, useNavigate} from "react-router-dom"
import {Image} from "react-bootstrap"
import MainButton from "../reusable/MainButton"
import {useEffect, useState} from "react";
import swal from "../reusable/CustomSweetAlert"
import UsersDAO from "../../daos/UsersDAO"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const SignUpPage = () => {

    const navigate = useNavigate()
    const {lightingMode, lightingBackground, lightingText, imageURL} = useOrganizationData()
    const [data, setData] = useState({})
    const [submittedButtonClicked, setSubmittedButtonClicked] = useState(false)

    const [value, setValue] = useState(new Date().toISOString())
    const [fmtValue, setFmtValue] = useState(undefined)

    function handleChange(value) {
        console.log('value', value)
        setData({...data, birth_date: value})
    }

    useEffect(() => {
        console.log(`Formatted value is ${new Date(value)}`)
    }, [fmtValue])

    const handleRegister = async () => {
        try {
            setSubmittedButtonClicked(true)
            // if(!data.name){
            //     swal.fireError({
            //         text: "Nama belum diisi!",
            //     })
            // }
            // if(!data.password){
            //     swal.fireError({
            //         text: "Kata sandi belum diisi!",
            //     })
            // }
            // if(data.password.length < 8){
            //     swal.fireError({
            //         text: "Kata sandi minimal 8 karakter!",
            //     })
            // }
            // if(!data.phone_number){
            //     swal.fireError({
            //         text: "Nomor ponsel belum diisi!",
            //     })
            // }
            // if(data.password != data.confirm_password){
            //     swal.fireError({
            //         text: "Nama belum diisi!",
            //     })
            // }

            let res = await UsersDAO.create(data)
            let okay = await swal.fire({
                title: "Registrasi Berhasil!",
                text: "Anda bisa masuk ke akun anda!",
                icon: 'success',
                confirmButtonText: 'Okay'
            })
            if (okay) return navigate('/login')
        } catch (e) {
            console.log(e)
            if (e.error_message === "DUPLICATE_EMAIL")
                swal.fireError({
                    title: "Registrasi gagal!",
                    text: "Email sudah terdaftar!",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
        }
    }

    return (
        <div style={{backgroundColor: lightingBackground, color: lightingText, minHeight: '100vh'}}>
            <Container className="pt-3 pb-3">
                {/* <Link to={'/'}>
                    <Image
                        style={{
                            // width: 40,
                            height: 40,
                            objectFit: "contain"
                        }}
                        src={imageURL}></Image>
                </Link> */}
                <div style={{gap: "2rem", fontSize: "0.9rem"}}>
                    <div style={{fontSize: "1.75rem", marginTop: "2rem"}}>
                        <b>Daftar</b>
                    </div>
                    <div style={{fontSize: "0.9rem", marginTop: "1rem",}}>Temukan buku-buku menarik di sini</div>
                    <Form style={{marginTop: "2rem", fontSize: "0.9rem"}}>
                        <FormGroup>
                            <Label>Nama Lengkap</Label>
                            <Input placeholder="Masukkan nama lengkap" onChange={(e) => {
                                setData({...data, name: e.target.value})
                            }} value={data.name}/>
                            {
                                submittedButtonClicked && !data.name &&
                                <Label style={{color: 'red'}}>Kolom nama harus diisi!</Label>
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input placeholder="Masukkan alamat email" onChange={(e) => {
                                setData({...data, email: e.target.value})
                            }} value={data.email}/>
                            {
                                submittedButtonClicked && !data.email &&
                                <Label style={{color: 'red'}}>Kolom email harus diisi!</Label>
                            }

                        </FormGroup>
                        <FormGroup>
                            <Label>Nomor HP</Label>
                            <Input placeholder="Masukkan nomor hp" onChange={(e) => {
                                setData({...data, phone_number: e.target.value})
                            }} value={data.phone_number}/>
                            {
                                submittedButtonClicked && !data.phone_number &&
                                <Label style={{color: 'red'}}>Kolom nomor hp harus diisi!</Label>
                            }

                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input type="password" placeholder="Masukkan password" onChange={(e) => {
                                setData({...data, password: e.target.value})
                            }} value={data.password}/>
                            {
                                submittedButtonClicked && !data.password &&
                                <>
                                    <Label style={{color: 'red'}}>Kolom password harus diisi!</Label>
                                    <br/>
                                </>
                            }
                            {
                                submittedButtonClicked && data.password && data.password.length < 8 &&
                                <>
                                    <Label style={{color: 'red'}}>Password harus memiliki minimal 8 karakter!</Label>
                                    <br/>
                                </>
                            }

                        </FormGroup>
                        <FormGroup>
                            <Label>Konfirmasi Password</Label>
                            <Input type="password" placeholder="Masukkan password" onChange={(e) => {
                                setData({...data, confirm_password: e.target.value})
                            }} value={data.confirm_password}/>
                            {
                                submittedButtonClicked && !data.confirm_password &&
                                <>
                                    <Label style={{color: 'red'}}>Kolom konfirmasi password harus diisi!</Label>
                                    <br/>
                                </>
                            }
                            {
                                submittedButtonClicked && data.password !== data.confirm_password &&
                                <Label style={{color: 'red'}}>Password tidak sama!</Label>
                            }
                            <br/>
                        </FormGroup>

                        <FormGroup>
                            <Label>Tanggal Lahir</Label>
                            <Input type="date" placeholder="Tanggal Lahir" onChange={(e) => {
                                setData({...data, birth_date: e.target.value})
                            }} value={data.birth_date}/>
                            <br/>
                        </FormGroup>

                        {/* <DatePicker
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            selected={value}
                            onSelect={handleChange} //when day is clicked
                        />  */}
                        {
                            submittedButtonClicked && !data.birth_date &&
                            <Label style={{color: 'red'}}>Kolom tanggal lahir harus diisi!</Label>
                        }
                    </Form>
                    <Container style={{display: "flex", flexDirection: "column", gap: "1rem", marginTop: 20}}>
                        {/* <Row><Button color="link" style={{ color: lightingText, textDecoration: "none", width: "fit-content", padding: 0, fontSize: "0.9rem" }}>Lupa password?</Button></Row> */}
                        <Row><MainButton onClick={handleRegister} color={"PRIMARY"} text={"Buat akun baru"}/></Row>
                        <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Udah punya akun
                            sebelumnya?&nbsp;<Button color="link" onClick={() => navigate("/login")} style={{
                                color: lightingText,
                                textDecoration: "none",
                                width: "fit-content",
                                padding: 0
                            }}><b>Log in</b></Button></Row>
                    </Container>
                </div>
            </Container>
        </div>
    )
}

export default SignUpPage