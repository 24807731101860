import Constants from "../../utils/Constants";
import Palette from "../../utils/Palette.";
import {Button, Form, Image, InputGroup} from "react-bootstrap";
import {Icon} from "@iconify/react";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import SettingsPopover from "../reusable/SettingsPopover";

export default function Header({windowWidth, font, setFont, fontSize, setFontSize, mode, setMode}) {

    const {imageURL, primaryColor, secondaryColor, name, favicon, status} = useOrganizationData()

    const navigate = useNavigate()
    const [showSearch, setShowSearch] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState(null)

    useEffect(() => {
        document.title = name

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = favicon

    }, [name, favicon])

    useEffect(() => {
        console.log("HEADERX", status)

        if (status === "NO_ORGANIZATION_DATA") {
            navigate("/pleaseScan")
        }
    }, [status])




    if (windowWidth <= 576) return (
        <>
            <div
                className="d-flex flex-row justify-content-start align-items-center"
                style={{height: Constants.HEADER_HEIGHT, background: primaryColor, padding: '16px 16px'}}>
                <div style={{color: Palette.TEXT_MAIN, display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>

                    <Link to={'/'}>
                        <Image
                            style={{
                                // width: 40,
                                height: 40,
                                objectFit: "contain"
                            }}
                            src={imageURL}></Image>
                    </Link>

                    <Button
                        id={'popover1'}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '18px',
                            cursor: 'pointer',
                            minWidth: 50,
                            backgroundColor: 'transparent',
                            border: '0'
                        }}
                    >
                        <Icon icon="material-symbols:settings" height={25} width={25}/>
                    </Button>
                    <SettingsPopover
                        font={font}
                        setFont={setFont}
                        setFontSize={setFontSize}
                        fontSize={fontSize}
                        mode={mode}
                        setMode={setMode}
                        target={'popover1'}
                    />
                </div>
            </div>
        </>
    )


    const handleLogout = () => {
        localStorage.removeItem("super_token")
        localStorage.removeItem("token")
        localStorage.removeItem("email")
        localStorage.removeItem("role")
        localStorage.removeItem("user_id")

        sessionStorage.removeItem("super_token")
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("email")
        sessionStorage.removeItem("role")
        sessionStorage.removeItem("user_id")

        navigate('/')
        window.location.reload()
    }

    const handleSettings = () => {
        navigate("/settings")
    }

    return (
        <>
            <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{height: Constants.HEADER_HEIGHT, background: primaryColor, padding: '16px 48px', display: 'flex'}}>
                <div style={{color: Palette.TEXT_MAIN, marginLeft: 20}}>
                    <Link to={'/'}>
                        <Image
                            style={{
                                // width: 40,
                                height: 40,
                                objectFit: "contain"
                            }}
                            src={imageURL}></Image>
                    </Link>

                </div>
                {showSearch &&
                    <InputGroup size="sm" style={{borderColor: 'rgba(255,255,255,0.5)', width: "20rem"}}>
                        <InputGroup.Text style={{
                            backgroundColor: 'rgba(255,255,255,0.5)',
                            color: Palette.THEME_BLACK,
                            borderColor: 'rgba(255,255,255,0.5)'
                        }}><Icon icon="material-symbols:search"/></InputGroup.Text>
                        <Form.Control
                            placeholder="Search"
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    //console.log(searchKeyword)
                                    navigate("/search/" + searchKeyword)
                                    setShowSearch(null)
                                    setShowSearch(false)
                                }
                            }}
                            style={{
                                backgroundColor: 'rgba(255,255,255,0.5)',
                                borderColor: 'rgba(255,255,255,0.5)',
                                color: Palette.THEME_BLACK
                            }}/>
                    </InputGroup>
                }
                <div className={'d-flex'} style={{gap: 20, color: Palette.TEXT_MAIN}}>
                    {!showSearch ? <div onClick={() => setShowSearch(true)}>
                            <a style={{
                                color: Palette.TEXT_MAIN,
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: "center"
                            }} href={'javascript:;'}><Icon icon="material-symbols:search"/>&nbsp;Cari</a>
                        </div> :
                        <div onClick={() => {
                            setShowSearch(false);
                            setSearchKeyword(null)
                        }}>
                            <a style={{
                                color: Palette.TEXT_MAIN,
                                textDecoration: 'none',
                                display: "flex",
                                alignItems: "center"
                            }} href={'javascript:;'}><Icon icon="material-symbols:close"/>&nbsp;Tutup</a>
                        </div>
                    }
                    {
                        (sessionStorage.token || localStorage.token) ?
                            <div onClick={() => navigate("/profile")} style={{cursor: "pointer"}}>
                                <Icon icon="iconamoon:profile-fill"/>
                            </div>
                            :
                            <div style={{cursor: "pointer"}} onClick={()=>navigate("/login")}>
                                Login
                            </div>
                    }
                    {/*<div onClick={handleSettings} style={{cursor: "pointer"}}>*/}
                    {/*    <Icon icon="material-symbols:settings" height={25} width={25}/>*/}
                    {/*</div>*/}
                    {/*{*/}
                    {/*    (sessionStorage.token || localStorage.token) &&*/}
                    {/*    <div onClick={handleLogout} style={{cursor: "pointer"}}>*/}
                    {/*        <Icon icon="material-symbols:logout"/>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>

            </div>
        </>
    )
}