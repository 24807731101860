import sweetalert from "sweetalert2";

const swal = {
    fire: (options) => {
        return sweetalert.fire({
            ...options,
            confirmButtonColor: sessionStorage.primary_color ? sessionStorage.primary_color : localStorage.secondary_color,
            customClass: {
                container: 'swal-font',
                ...options.customClass,
            }
        })
    },
    fireError : (options) => {
        return sweetalert.fire({
            title: 'Error',
            icon: 'error',
            confirmButtonText: 'Okay',
            confirmButtonColor: sessionStorage.primary_color ? sessionStorage.primary_color : localStorage.secondary_color,
            customClass: {
                container: 'swal-font',
                ...options.customClass,
            },
            ...options,
        })
    },
}

export default swal;