import { useState, useContext, createContext, useEffect } from 'react';
// import OrganizationDataContext from './context';
import OrganizationModel from '../daos/OrganizationModel';
import apiConfig from './apiConfig';
import Palette from './Palette.';

const OrganizationDataContext = createContext({});

const useOrganizationData = () => {
    return useContext(OrganizationDataContext);
}

const OrganizationDataProvider = ({ children, mode }) => {

    const updateData = async (id) => {
        try {
            let organization = await OrganizationModel.getOrganizationById(id ? id : apiConfig.publisherId)



            let formattedTheme = {
                name : organization.name,
                imageURL : organization.image_url ?? "https://www.ogaratraining.com/wp-content/uploads/2017/04/Image-Placeholder-wide.jpg",
                primaryColor : organization.primary_color,
                secondaryColor : organization.secondary_color,
                lightingMode : organization.lighting_mode,
                lightingBackground: mode === "DARK" ? Palette.DARK_MODE_BACKGROUND : Palette.LIGHT_MODE_BACKGROUND,
                lightingText: mode ==="DARK"? Palette.DARK_MODE_TEXT : Palette.LIGHT_MODE_TEXT,
                favicon: organization.favicon,
                updateData : updateData
            }
            
            console.log("FTM", formattedTheme)

            setOrganizationData(formattedTheme)

            localStorage.publisherId = id
            sessionStorage.login_required = organizationData.organization.login_required
            localStorage.login_required = organizationData.organization.login_required

        } catch (e) {
            console.log(e)
        }
    }

    const initializeData=()=>{

        let publisherId = localStorage.getItem("publisherId")

        console.log("HEADERx going to initialize", publisherId)

        if(publisherId){
            updateData(publisherId)
        }else{
            setOrganizationData({
                ...organizationData,
                status : "NO_ORGANIZATION_DATA"
            })
        }

    }

    const [organizationData, setOrganizationData] = useState({
        updateData : updateData,
        status : "NOT_YET_INITIALIZED"//NOT_YET_INITIALIZED, "EXPIRED", "NO_ORGANIZATION_DATA"
    });

    useEffect(() => {
        initializeData()
    }, [mode]);

    // const updateData = async(id)=>{
    //     alert("")
    // }



    const value = {
        ...organizationData
    };
    return (
        <OrganizationDataContext.Provider value={value}>
            {children}
        </OrganizationDataContext.Provider>
    )
}

export {
    useOrganizationData,
    OrganizationDataContext,
    OrganizationDataProvider
}