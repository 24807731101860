import {useEffect} from "react"
import {useParams, useNavigate} from "react-router-dom"
import OrganizationModel from "../../daos/OrganizationModel"
import {useOrganizationData} from "../../utils/OrganizationDataProvider"
import QrScanLogs from "../../daos/QrScanLogs"

export default function ScanResultPage({}) {
    let token = sessionStorage.token || localStorage.token;
    let {code} = useParams()
    let navigate = useNavigate()
    const {updateData} = useOrganizationData()

    useEffect(() => {
        fetchOrganizationDataFromQR()
        createLogs()
    }, [])

    const createLogs = async () => {
        try{
            let user_id = sessionStorage.getItem("user_id") || localStorage.getItem('user_id')
            let body = {code, user_id: user_id ? parseInt(user_id) : null}
            let result = await QrScanLogs.createQrScanLog(body)
            localStorage.code = code;
            sessionStorage.code = code;

            console.log('result', result)
        }catch (e) {
            console.log(e)
        }
    }

    function isLessThanCurrentDate(date) {
        const currentDate = new Date();
        if (typeof date === 'string') {
            date = new Date(date);
        }

        return date < currentDate;
    }

    const fetchOrganizationDataFromQR = async () => {
        try {
            let organizationData = await OrganizationModel.scanQR(code)
            console.log("ORGAN orgDATA", organizationData)

            sessionStorage.primary_color = organizationData.organization.primary_color
            sessionStorage.secondary_color = organizationData.organization.secondary_color
            sessionStorage.qr_token = organizationData.token
            sessionStorage.login_required = organizationData.organization.login_required

            localStorage.primary_color = organizationData.organization.primary_color
            localStorage.secondary_color = organizationData.organization.secondary_color
            localStorage.qr_token = organizationData.token
            localStorage.login_required = organizationData.organization.login_required


            await updateData(organizationData.organization_id)

            if(isLessThanCurrentDate(organizationData.valid_until)) {
                return navigate("/expired")
            }

            navigate("/splash")
        } catch (e) {
            navigate("/incorrect")
            console.error(e)
        }
    }

    return <>
        <div style={{
            backgroundColor: "#000000",
            color: "#FFFFFF",
            minHeight: '100vh',
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            Sesi anda sudah habis, harap perpanjang durasi kadaluarsa QR.

        </div>
    </>
}