import { Container, Form, Label, FormGroup, Input, Button, Row } from "reactstrap"
import { useOrganizationData } from "../../utils/OrganizationDataProvider"
import { Link } from "react-router-dom"
import { Image } from "react-bootstrap"
import MainButton from "../reusable/MainButton"
import { useEffect, useState } from "react"
const PleaseScan = () => {

  const { status } = useOrganizationData()
  const [wording, setWording] = useState("")

  useEffect(() => {

    document.title = "Harap memindai QR"
    setWording("QR tidak ditemukan. Harap pindai QR dari perpustakaan digital terdekat.")

    if(status === "EXPIRED"){
      document.title = "Sesi anda sudah habis"
      setWording("Sesi anda sudah habis, haram pindai ulang QR perpustakaan digital.")

    }

  }, [status])

  return (
    <div style={{ backgroundColor: "#000000", color: "#FFFFFF", minHeight: '100vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
      {wording}
    </div>
  )
}

export default PleaseScan