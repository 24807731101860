import {Card, Col, Container, Image, Row} from "react-bootstrap";
import MainButton from "../reusable/MainButton";
import Palette from "../../utils/Palette.";
import Book from "../../daos/Book";
import Category from "../../daos/Category";
import CardThumb from "../reusable/CardThumb";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import UsersDAO from "../../daos/UsersDAO";
import BookUserDAO from "../../daos/BookUserDAO";
import {isMobile} from 'react-device-detect';

export default function ProfilePage({}) {
    const [user, setUser] = useState({})
    const navigate = useNavigate()
    const [favoriteBooks, setFavoriteBooks] = useState([])
    const [continueReadingBooks, setContinueReadingBooks] = useState([])
    const [categories, setCategories] = useState([])
    const {lightingMode, primaryColor, secondaryColor} = useOrganizationData()

    useEffect(() => {
        if (!sessionStorage.token && !localStorage.token) {
            navigate("/login")
        }

        // const initialize = async () => {
        //     try {
        //         //const result = await Book.getAll()
        //         const categoriesTemp = await Category.getAll()
        //
        //         const bookCategories = result.map(data => data.book_category.map(data2 => {return data2.categories.name}))
        //         for(let i = 0; i < result.length; i++) {
        //             result[i].categories = bookCategories[i]
        //         }
        //         setFavoriteBooks(result)
        //         setCategories(categoriesTemp.filter(data => data.show_homepage == true))
        //     } catch (e) {
        //         console.log(e)
        //     }
        // }

        const getUser = async () => {
            try {
                let result = await UsersDAO.getSelf()
                setUser(result)
            } catch (e) {
                console.log('getSelf', e)

            }
        }

        const getHistory = async () => {
            try {
                let result = await BookUserDAO.getHistory()
                console.log('get history', result)
                setContinueReadingBooks(result.map((res) => {
                    let resTemp = {progress: res.progress}
                    resTemp = {...resTemp, ...res.books}
                    return resTemp
                }))

                console.log('Book History', result)
            } catch (e) {
                console.log(e)
            }
        }

        const getFavorite = async () => {
            try {
                let result = await BookUserDAO.getFavorite()
                setFavoriteBooks(result.map(res => res.books))

                console.log('Book favorite', result)
            } catch (e) {
                console.log(e)
            }
        }

        getHistory()
        //initialize()
        getFavorite()
        getUser()
    }, [])

    const handleLogout = () => {
        localStorage.removeItem("super_token")
        localStorage.removeItem("token")
        localStorage.removeItem("email")
        localStorage.removeItem("role")
        localStorage.removeItem("user_id")

        sessionStorage.removeItem("super_token")
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("email")
        sessionStorage.removeItem("role")
        sessionStorage.removeItem("user_id")

        navigate('/')
        window.location.reload()
    }

    return <>
        <Container style={{marginBottom: 20}} className="mt-4">
            <Row>
                <Col md={3}>
                    <Card style={{alignItems: 'center', /* background:Palette.THEME_SECONDARY */}}>
                        <Card.Body>
                            <Image style={{width: 200, height: 200}}
                                   src={user?.image_url ? user.image_url : 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg'}
                                   roundedCircle fluid/>
                            <Row className={'mt-2'}>
                                <a style={{color: primaryColor, textDecoration: 'none'}} className={'text-center'}
                                   href={user.image_url}>Ubah gambar profil</a>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
                <Col md={9}>
                    <Card style={{/* background: Palette.THEME_SECONDARY, color: Palette.TEXT_MAIN */
                        marginTop: isMobile && 10,
                        border: '0px',
                        borderRadius: '10px'
                    }}>
                        <Card.Body>
                            <Row className={'mb-4'}>
                                <Col lg={6}>
                                    <Card.Title>Nama Lengkap</Card.Title>
                                    <Card.Text>{user.name}</Card.Text>
                                </Col>
                                <Col lg={6} style={{marginTop: isMobile && 20}}>
                                    <Card.Title>Email</Card.Title>
                                    <Card.Text>{user.email}</Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Card.Title>Tanggal Lahir</Card.Title>
                                    <Card.Text>{`${String(new Date(user.birth_date).getDate()).padStart(2, '0')}-${String(new Date(user.birth_date).getDate()).padStart(2, '0')}-${new Date(user.birth_date).getFullYear()}`}</Card.Text>
                                </Col>
                            </Row>


                        </Card.Body>
                        <div className={'d-grid mt-3'}>
                            <MainButton
                                icon={'mdi:pencil'}
                                color={'PRIMARY'}
                                text={"Kelola Profil"}
                                style={{
                                    borderTopLeftRadius: '0px'
                                }}
                                secondary_button={{
                                    text: 'Logout',
                                    icon: 'material-symbols:logout',
                                    color: 'SECONDARY',
                                    onClick: handleLogout,
                                    style: {
                                        borderTopRightRadius: '0px',
                                    }
                                }}
                            />
                        </div>
                    </Card>
                    {/* Add additional profile details or content here */}
                </Col>
            </Row>
            <Row className={'mt-5'}>
                <Row>
                    <h2 style={{}} className={'mb-3 mt-3'}>Daftar Saya</h2>
                </Row>
                <Row>
                    {
                        /* Book.BooksDummy?.map((data) => {
                            return (
                                <>
                                    <Col className={'mb-3'} lg={2} xl={2}>
                                        <CardThumb
                                            cover={data.cover}
                                            url={'/book/1'}
                                        />
                                    </Col>
                                </>
                            )
                        }) */
                        favoriteBooks.map((data, idx) => {
                            return (
                                <>
                                    <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                                        <CardThumb
                                            cover={data.cover}
                                            url={'/book/' + data.id}
                                            title={data.title}
                                            author={data.author}
                                        />
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Row>
            <Row className={'mt-5'}>
                <Row>
                    <h2 style={{}} className={'mb-3 mt-3'}>Lanjut Baca</h2>
                </Row>
                <Row>
                    {
                        /* Book.BooksDummy?.map((data) => {
                            return (
                                <>
                                    <Col className={'mb-3'} lg={2} xl={2}>
                                        <CardThumb
                                            cover={data.cover}
                                            url={'/book/1'}
                                        />
                                    </Col>
                                </>
                            )
                        }) */
                        continueReadingBooks.map((data, idx) => {
                            console.log('data', data)
                            let progressPercentage = (data.progress / data.number_of_chapters) * 100
                            return (
                                <>
                                    <Col className={'mb-3'} xs={4} lg={2} xl={2}>
                                        <CardThumb
                                            progress={progressPercentage}
                                            cover={data.cover}
                                            url={`/read/button/${data.id}/${data.progress}`}
                                            title={data.title}
                                            author={data.author}
                                        />
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
            </Row>
        </Container>
    </>
}