import {ButtonGroup, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";

export default function SettingsPage() {
    const [font, setFont] = useState(localStorage.font || 'Arial');
    const [fontSize, setFontSize] = useState(parseFloat(localStorage.font_size ) || 0)

    useEffect(() => {
        localStorage.font = font
        sessionStorage.font = font
    }, [fontSize, font]);

    useEffect(() => {
        console.log('fontSize before', localStorage.font_size)
        console.log('fontSize after', fontSize)
        localStorage.font_size = fontSize
        sessionStorage.font_size = fontSize
    }, [fontSize]);


    return(
        <Container>
            <Row className={'p-3'}>
                <h2>
                    Pengaturan
                </h2>
            </Row>
            <Row>
                <FormGroup onChange={e => setFont(e.target.value)}>
                    <Label for="exampleSelect">
                        Font
                    </Label>
                    <Input
                        value={font}
                        id="exampleSelect"
                        name="select"
                        type="select"
                    >
                        <option>
                            Arial
                        </option>
                        <option>
                            Times New Roman
                        </option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>
                        Font Size
                    </Label> <br/>
                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                let fontSizeTemp = fontSize - 0.25
                                setFontSize(fontSizeTemp)
                            }}
                            style={{backgroundColor: 'grey', border: 'none'}}>
                            -
                        </Button>
                        <Button style={{backgroundColor: 'grey', border: 'none'}}>
                            {fontSize > 0 && '+'}{fontSize}
                        </Button>
                        <Button
                            onClick={() => {
                                let fontSizeTemp = fontSize + 0.25
                                setFontSize(fontSizeTemp)
                            }}
                            style={{backgroundColor: 'grey', border: 'none'}}>
                            +
                        </Button>
                    </ButtonGroup>
                </FormGroup>
            </Row>
        </Container>
    )
}