import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import OrganizationModel from "../../daos/OrganizationModel"
import { useOrganizationData } from "../../utils/OrganizationDataProvider"
import {RemoveScrollBar} from 'react-remove-scroll-bar';
import {isMobile} from 'react-device-detect';

export default function SplashScreenPage({}){

    let navigate = useNavigate()

    useEffect(()=>{
        setTimeout(()=>{
            navigate("/")
        }, 3000)
    },[])

    const { imageURL, primaryColor } = useOrganizationData()
    return <div style={{height : "100vh", width : "100vw", display : "flex", alignItems : "center", justifyContent :"center", background : primaryColor}}>
        <RemoveScrollBar /> 
        <img style={{height  : isMobile ? '20vw' : "10vw", objectFit : "contain"}} src={imageURL}/>
    </div>
}