import API from "../utils/API";

export default class Category {
    static getAll = async () => {
        return await API.set("v1/categories", "GET")
    }

    static getByID = async (id) => {
        return await API.set("v1/category/" + id, "GET")
    }


}