import API from "../utils/API";

export default class UsersDAO {

    static login = async (body) => {
        return await API.set(`v1/user/login`, "POST", body)
    }

    static create = async (body) => {
        return await API.set(`v1/user`, "POST", body)
    }

    static sendMailForget = async (body) => {
        return await API.set(`v1/user/forgot-password`, "POST", body)
    }

    static sendResetPasword = async (body) => {
        return await API.set(`v1/user/reset-password`, "POST", body)
    }

    static getSelf = async () => {
        return await API.set('v1/user-self', 'GET')
    }

}