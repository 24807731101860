import API from "../utils/API";

export default class BookUserDAO {
    static saveProgress = async (book_id, chapter_num) => {
        return await API.set(`v1/book-user/save-progress/${book_id}/${chapter_num}`, 'GET')
    }

    static saveProgressScroll = async (book_id, chapter_num, body) => {
        return await API.set(`v1/book-user/save-progress-scrolling/${book_id}/${chapter_num}`, 'POST', body)
    }

    static like = async (book_id, body) => {
        return await API.set(`v1/book-user/like/${book_id}`, 'POST', body)
    }

    static getByUserIdAndBookId = async (book_id) => {
        return await API.set(`v1/book-user/${book_id}/user-progress`, 'GET')
    }

    static getHistory = async (book_id) => {
        return await API.set(`v1/book-user/user/self/history`, 'GET')
    }


    static getFavorite = async () => {
        return await API.set('v1/book-user/user/self/favorite', 'GET')
    }

    static logThis = async (params) => {
        return await API.set(`v1/book-user-log-this/${params}`, 'GET')
    }

    static setFavorite = async (book_id) => {
        return await API.set(`v1/book-user/${book_id}/user/favorite`, 'GET')
    }

    static countBookReaderByBookId = async (book_id) => {
        return await API.set(`v1/book-user/count/${book_id}`, 'GET')
    }

}