import {Button, ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {useEffect} from "react";
import {Icon} from "@iconify/react";

export default function ChapterList({chapters, progress}) {

    return (
        <>
            {
                chapters?.map((data) => {
                    return (
                        <>
                            <div className="d-grid gap-2 mb-3">
                                <Link style={{textDecoration: 'none'}} to={`/read/${data?.book_id}/${data?.chapter_num}`} className={'w-100 d-grid'} state={{chapters: chapters}}>
                                    <Button style={{background: "#404040", borderColor: '#404040', borderRadius: 4}}
                                            variant="secondary" size="lg">
                                        <div style={{
                                            lineHeight: 1.2,
                                            fontSize: '0.8em',
                                            textAlign: 'left'
                                        }}>Bagian {data.chapter_num}:
                                            {` ${data?.title}`}
                                            {
                                                 data.chapter_num <= progress?.progress &&
                                                <Icon icon={`ic:baseline-check`} style={{marginLeft: 10, marginBottom: 5}} />

                                            }
                                        </div>
                                        <div style={{textAlign: 'left', fontSize: '0.6em', color: '#9E9E9E'}}>
                                            {`Diunggah: ${dayjs(data?.created_at).format('DD MMMM YYYY HH:MM')}`}
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                        </>
                    )
                })

            }

        </>
    )
}