import {Container} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Chapter from "../../daos/Chapter";
import BookUserDAO from "../../daos/BookUserDAO";
import {isMobile} from 'react-device-detect';
import CryptoJS from 'crypto-js';

export default function BookReadPageInfiniteScrollMode() {
    const { id, bookId, chapterNum } = useParams();
    const [chapters, setChapters] = useState([]);
    const chapterRefs = useRef({});
    const bottomRef = useRef(null);
    const canvasRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const decrypt = (text) => {
        try {
            return CryptoJS.AES.decrypt(text, '0123456789abcdef0123456789abcdef').toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Error decrypting:', error);
            return null;
        }
    };

    useEffect(() => {
        if (!isLoaded && chapters.length > 0) {
            chapterRefs.current[id]?.scrollIntoView({ behavior: "smooth" })
            setIsLoaded(true);
        }
    }, [chapters]);

    // const hashSHA1 = (str) => {
    //     return crypto.createHash('sha1').update(str).digest('hex')
    // }
    //
    // const generatePassword = (password, salt) => {
    //     return hashSHA1(salt + password)
    // }

    useEffect(() => {
        let getData = async () => {
            try {
                let chaptersTemp = [];
                let result = await Chapter.getChapterByBookIdAndChapterNumber(bookId, chapterNum)
                if(result.content) result.content = decrypt(result.content)
                chaptersTemp.push(result)

                setChapters(chaptersTemp);
            } catch (e) {
                console.log(e);
            }
        };
        getData();
    }, []);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (bottomRef.current) {
                const rect = bottomRef.current.getBoundingClientRect();
                const isVisible =
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <=
                    (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth);
                setIsVisible(isVisible);
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const saveProgress = async () => {
            let token =
                sessionStorage.getItem("token") ||
                localStorage.getItem("token");
            if (token) {
                let latestChapter = { ...chapters[chapters.length - 1] };
                console.log("save progress");
                try {
                    let result = await BookUserDAO.saveProgress(
                        bookId,
                        latestChapter.chapter_num
                    );
                    console.log("save progress", result);
                } catch (e) {
                    console.log(e);
                }
            }
        };

        const loadMoreChapters = () => {
            try {
                console.log("scrolled to the bottom");
                console.log('invoked!')

                if (chapters.length > 0) {
                    let nextChapter = chapters[chapters.length - 1].chapter_num + 1;
                    const chapterExist = chapters.find(
                        (chapter) => chapter.chapter_num === nextChapter
                    );
                    if (!chapterExist) {
                        Chapter.getChapterByBookIdAndChapterNumber(
                            bookId,
                            nextChapter
                        ).then((res) => {
                            if(res){
                                let chapterTemp = {...res,content: decrypt(res.content)}
                                if (res) setChapters((prevChapters) => [...prevChapters, chapterTemp]);
                            }
                        });
                    }
                }
            } catch (error) {
                console.error("Error loading more chapters:", error);
            }
        };

        if (isVisible) {
            loadMoreChapters();
            saveProgress();
        }
    }, [isVisible]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        let totalHeight = 20; // Initial y offset
        chapters.forEach((chapter) => {
            context.font = "16px Arial";
            context.fillStyle = "#f9f9f9";
            const lines = getWrappedLines(context, chapter.content, canvas.width - 20);
            totalHeight += lines.length * 25 + 40; // Increase line height by adjusting yOffset
        });
        canvas.height = totalHeight;
        context.clearRect(0, 0, canvas.width, canvas.height);

        let yOffset = 30; // Initial y offset
        chapters.forEach((chapter) => {
            context.font = "20px Arial"; // Font size for chapter number
            context.fillStyle = "#ffffff";
            context.textAlign = "center";
            context.fillText(`Bagian ${chapter.chapter_num}`, canvas.width / 2, yOffset);

            context.font = "20px Arial"; // Font size for chapter title
            yOffset += 30; // Increase yOffset for next chapter
            context.textAlign = "center";
            context.fillText(chapter.title, canvas.width / 2, yOffset);

            context.font = "12px Arial"; // Font size for chapter content
            context.fillStyle = "#ffffff";
            yOffset += 40; // Increase yOffset for next line
            context.textAlign = "left";
            const lines = getWrappedLines(context, chapter.content, canvas.width - 20);
            lines.forEach((line) => {
                context.fillText(line, 10, yOffset);
                yOffset += 25; // Increase line height by adjusting yOffset
            });
            yOffset += 20; // Increase yOffset for next line
        });
    }, [chapters]);

    // Function to wrap text into multiple lines based on line breaks
    const getWrappedLines = (context, text, maxWidth) => {
        const lines = text.split('\n');
        let wrappedLines = [];
        lines.forEach((line) => {
            const words = line.split(" ");
            let wrappedLine = "";
            words.forEach((word) => {
                const testLine = wrappedLine + word + " ";
                const metrics = context.measureText(testLine);
                const testWidth = metrics.width;
                if (testWidth > maxWidth && wrappedLine !== "") {
                    wrappedLines.push(wrappedLine);
                    wrappedLine = word + " ";
                } else {
                    wrappedLine = testLine;
                }
            });
            wrappedLines.push(wrappedLine);
        });
        return wrappedLines;
    };

    return (
        <Container style={{ width: "100%", minHeight: "100vh", marginBottom: "2rem",
            display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: ''
        }} fluid>
            <canvas ref={canvasRef} width={isMobile ? '300%' : '1100%'}  />
            <div style={{ opacity: 1 }} ref={bottomRef}>
                Load more
            </div>
        </Container>
    );
}
