import API from "../utils/API";

export default class Book {
    static getAll = async () => {
        return await API.set("v1/books", "GET")
    }

    static getAllByCode = async (code) => {
        return await API.set(`v1/books/code/${code}`, "GET")
    }

    static getAllByCodeAndCategory = async (code, categoryId) => {
        return await API.set(`v1/books/code-category/${code}/${categoryId}`, "GET")
    }

    static search = async (body) => {
        return await API.set("v1/book/search", "POST", body)
    }
    static searchRestricted = async (body) => {
        return await API.set("v1/book/search-restricted", "POST", body)
    }

    static getByID = async (id) => {
        return await API.set("v1/book-user/" + id, "GET")
    }

    static getChapters = async (id) => {
        return await API.set("v1/chapter/book/" + id, "GET")
    }

    static getLikesAndDislikes = async (id) => {
        return await API.set("v1/book-user/likes-dislikes/" + id, "GET")
    }

    static BooksHighlight ={
            title : 'Life is Yours : Hidup ini Milikmu, bukan Milik Orang Lain',
            description : `
            Tidak apa-apa untuk tidak tahu banyak hal, karena kamu manusia biasa, bukan Google. Tidak apa-apa untuk mengalami hal-hal yang memalukan, tidak apa-apa untuk belum jadi siapa-siapa di usiamu yang sudah seperempat abad ini. Tidak apa-apa untuk membenci yang disukai orang lain, dan menyukai yang dibenci oleh orang lain.
            Hidup bukan atas apa yang dikatakan orang, bukan pula atas apa yang ada di genggaman tangan orang. Life is yours, hidup ini milikmu. Kamu yang berhak menentukan kemana kakimu melangkah, kamu yang berhak milih, mau jalan cepat ataukah lambat. Selagi kamu bahagia, kamu pasti akan menikmatinya
            `,
            cover : 'https://cdn.gramedia.com/uploads/picture_meta/2024/3/2/hpdxelhh4vxrpicnrdtrws.jpg',
        chapters : [
            {
                chapter_num : 1,
                title : 'Judul Bagian',
                created_at : '2024-02-22 09:09:57',
                content : `
Georgina was at the station to meet her three cousins. Timmy her dog was
with her, his long tail wagging eagerly. He knew quite well they had
come to meet Julian, Dick and Anne, and he was glad. It was much more
fun when the Five were all together.

‘Here comes the train, Timmy!’ said George. Nobody called her Georgina,
because she wouldn’t answer if they did. She looked like a boy with her
short curly hair and her shorts and open–necked shirt. Her face was
covered with freckles, and her legs and arms were as brown as a gipsy’s.

There was the far–off rumble of a train, and as it came nearer, a short
warning hoot. Timmy whined and wagged his tail. He didn’t like trains,
but he wanted this one to come.

Nearer and nearer it came, slowing down as it reached Kirrin station.
Long before it came to the little platform three heads appeared out of
one of the windows, and three hands waved wildly. George waved back, her
face one big smile.

The door swung open almost before the train stopped. Out came a big boy,
and helped down a small girl. Then came another boy, not quite so tall
as the first one, with a bag in each hand. He dragged a third bag out,
and then George and Timmy were on him.

‘Julian! Dick! Anne! Your train’s late; we thought you were never
coming!’

‘Hallo, George! Here we are at last. Get down, Timmy, don’t eat me.’

‘Hallo, George! Oh, Timmy, you darling—you’re just as licky as ever!’

‘Woof,’ said Timmy joyfully, and bounded all round like a mad thing,
getting into everybody’s way.

‘Any trunk or anything?’ asked George. ‘Only those three bags?’

‘Well, we haven’t come for long this time, worse luck,’ said Dick. ‘Only
a fortnight! Still, it’s better than nothing.’

‘You shouldn’t have gone off to France all those six weeks,’ said
George, half–jealously. ‘I suppose you’ve gone all French now.’

Dick laughed, waved his hands in the air and went off into a stream of
quick French that sounded just like gibberish to George. French was not
one of her strong subjects.

‘Shut up,’ she said, giving him a friendly shove. ‘You’re just the same
old idiot. Oh, I’m so glad you’ve come. It’s been lonely and dull at
Kirrin without you.’

A porter came up with a barrow. Dick turned to him waved his hands
again, and addressed the astonished man in fluent French. But the porter
knew Dick quite well.

‘Go on with you,’ he said. ‘Argy–bargying in double–Dutch like that. Do
you want me to wheel these up to Kirrin Cottage for you?’

‘Yes, please,’ said Anne. ‘Stop it, Dick. It isn’t funny when you go on
so long.’

‘Oh, let him go on,’ said George, and she linked her arms in Anne’s and
Dick’s. ‘It’s lovely to have you again. Mother’s looking forward to
seeing you all.’

‘I bet Uncle Quentin isn’t,’ said Julian, as they went along the little
platform, Timmy capering round them.

‘Father’s in quite a good temper,’ said George. ‘You know he’s been to
America with Mother, lecturing and hearing other scientists lecturing
too. Mother says everyone made a great fuss of him, and he liked it.’

George’s father was a brilliant scientist, well–known all over the
world. But he was rather a difficult man at home, impatient,
hot–tempered and forgetful. The children were fond of him, but held him
in great respect. They all heaved a sigh of relief when he went away for
a few days, for then they could make as much noise as they liked, tear
up and down the stairs, play silly jokes and generally be as mad as they
pleased.

‘Will Uncle Quentin be at home all the time we’re staying with you?’
asked Anne. She was really rather afraid of her hot–tempered uncle.

‘No,’ said George. ‘Mother and Father are going away for a tour in
Spain—so we’ll be on our own.’

‘Wizard!’ said Dick. ‘We can wear our bathing costumes all day long then
if we want to.’

‘And Timmy can come in at meal–times without being sent out whenever he
moves,’ said George. ‘He’s been sent out every single meal–time this
week, just because he snapped at the flies that came near him. Father
goes absolutely mad if Timmy suddenly snaps at a fly.’

‘Shame!’ said Anne, and patted Timmy’s rough–haired back. ‘You can snap
at every single fly you like, Timmy, when we’re on our own.’

‘Woof,’ said Timmy, gratefully.

‘There won’t be time for any adventure these hols,’ said Dick,
regretfully, as they walked down the lane to Kirrin Cottage. Red poppies
danced along the way, and in the distance the sea shone as blue as
cornflowers. ‘Only two weeks—and back we go to school! Well, let’s hope
the weather keeps fine. I want to bathe six times a day!’

Soon they were all sitting round the tea–table at Kirrin Cottage, and
their Aunt Fanny was handing round plates of her nicest scones and
tea–cake. She was very pleased to see her nephews and niece again.

‘Now George will be happy,’ she said, smiling at the hungry four. ‘She’s
been going about like a bear with a sore head the last week or two. Have
another scone, Dick? Take two while you’re about it.’

‘Good idea,’ said Dick, and helped himself. ‘Nobody makes scones and
cakes like you do, Aunt Fanny. Where’s Uncle Quentin?’

‘In his study,’ said his aunt. ‘He knows it’s tea–time, and he’s heard
the bell, but I expect he’s buried in something or other. I’ll have to
fetch him in a minute. I honestly believe he’d go without food all day
long if I didn’t go and drag him into the dining–room!’

‘Here he is,’ said Julian, hearing the familiar impatient footsteps
coming down the hall to the dining–room. The door was flung open. Uncle
Quentin stood there, a newspaper in his hand, scowling. He didn’t appear
to see the children at all.

‘Look here, Fanny!’ he shouted. ‘See what they’ve put in this paper—the
very thing I gave orders was NOT to be put in! The dolts! The idiots!
The … ’

‘Quentin! Whatever’s the matter?’ said his wife. ‘Look—here are the
children—they’ve just arrived.’

But Uncle Quentin simply didn’t see any of the four children at all. He
went on glaring at the paper. He rapped at it with his hand.

‘Now we’ll get the place full of reporters wanting to see me, and
wanting to know all about my new ideas!’ he said, beginning to shout.
‘See what they’ve said! “This eminent scientist conducts all his
experiments and works out all his ideas at his home, Kirrin Cottage.
Here are his stack of notebooks, to which are now added two more—fruits
of his visit to America, and here at his cottage are his amazing
diagrams,” and so on and so on.

‘I tell you, Fanny, we’ll have hordes of reporters down.’

‘No, we shan’t, dear,’ said his wife. ‘And, anyway, we are soon off to
Spain. Do sit down and have some tea. And look, can’t you say a word to
welcome Julian, Dick and Anne?’

Uncle Quentin grunted and sat down. ‘I didn’t know they were coming,’ he
said, and helped himself to a scone. ‘You might have told me, Fanny.’

‘I told you three times yesterday and twice today,’ said his wife.

Anne suddenly squeezed her uncle’s arm. She was sitting next to him.
‘You’re just the same as ever, Uncle Quentin,’ she said. ‘You never,
never remember we’re coming! Shall we go away again?’

Her uncle looked down at her and smiled. His temper never lasted very
long. He grinned at Julian and Dick. ‘Well, here you are again!’ he
said. ‘Do you think you can hold the fort for me while I’m away with
your aunt?’

‘Rather!’ said all three together.

‘We’ll keep everyone at bay!’ said Julian. ‘With Timmy’s help. I’ll put
up a notice: “Beware, very fierce dog”.’

‘Woof,’ said Timmy, sounding delighted. He thumped his tail on the
floor. A fly came by his nose and he snapped at it. Uncle Quentin
frowned.

‘Have another scone, Father?’ said George hurriedly. ‘When are you and
Mother going to Spain?’

‘Tomorrow,’ said her mother firmly. ‘Now don’t look like that, Quentin.
You know perfectly well it’s been arranged for weeks, and you need a
holiday, and if we don’t go tomorrow all our arrangements will be
upset.’

‘Well, you might have warned me it was tomorrow,’ said her husband,
looking indignant. ‘I mean—I’ve all my notebooks to check and put away,
and … ’

‘Quentin, I’ve told you heaps of times that we leave on September the
third,’ said his wife, still more firmly. ‘I want a holiday, too. The
four children will be quite all right here with Timmy—they’ll love
being on their own. Julian is almost grown–up now and he can cope with
anything that turns up.’

Timmy snapped twice at a fly, and Uncle Quentin jumped. ‘If that dog
does that again,’ he began, but his wife interrupted him at once.

‘There, you see! You’re as touchy and nervy as can be, Quentin, dear. It
will do you good to get away—and the children will have a lovely two
weeks on their own. Nothing can possibly happen, so make up your mind to
leave tomorrow with an easy mind!’

Nothing can possibly happen? Aunt Fanny was wrong of course. Anything
could happen when the Five were left on their own!


`
            },
            {
                chapter_num : 2,
                title : 'Judul Bagian',
                created_at : '2024-02-22 09:09:57',
                content : `CHAPTER ONE
`
            },
            {
                chapter_num : 3,
                title : 'Judul Bagian',
                created_at : '2024-02-22 09:09:57',
                content : `CHAPTER ONE
`
            },
            {
                chapter_num : 4,
                title : 'Judul Bagian',
                created_at : '2024-02-22 09:09:57',
                content : `CHAPTER ONE
`
            },
            {
                chapter_num : 5,
                title : 'Judul Bagian',
                created_at : '2024-02-22 09:09:57',
                content : `CHAPTER ONE
`
            }
        ]
    }
    static BooksDummy = [
        {
            title : 'Naruto Bind Up Edition 02',
            description : 'Si manusia iblis, Zabuza, yang seharusnya telah dikalahkan oleh Sharingan Kakashi, ternyata masih hidup! Kelompok Naruto, yang telah tiba di negara Nami untuk mengawal Pak Tazuna, dilanda keraguan. Tapi, ini bukan saatnya untuk takut! Di bawah bimbingan Guru Kakashi',
            cover : 'https://cdn.gramedia.com/uploads/picture_meta/2024/1/26/ukxpx6wsmqtgqqtgcltr9j.jpg'
        },
        {
            title : 'Melangkah',
            description : 'Novel karya J. S Khairen yang berjudul Melangkah bertemakan tentang petualangan di Indonesia. Tidak hanya itu, cerita dalam novel ini juga mengutamakan kisah pahlawan. Berbeda dari karya-karya yang sebelumnya, di novel ini Khairen memberi sedikit imajinasi yang ia tanamkan. Terdapat 36 episode dan 5 babak.',
            cover : 'https://cdn.gramedia.com/uploads/items/9786020523316_Melangkah_UV_Spot_R4-1.jpg'
        },
        {
            title : 'Detektif Conan 103',
            description : 'Hotel tempat Sera Dan Mary menginap menerima surat ancaman dari pelaku pengeboman! Conan dan pengacara Kisaki harus segera mencari si pelaku di antara berbagai rencana dan pikiran yang bertautan satu sama lain di hotel itu!! Kelompok detektif cilik pergi berwisata ke pantai bersama Subaru Okiya',
            cover : 'https://cdn.gramedia.com/uploads/picture_meta/2023/10/31/egxmfc5jkllsmadjjcklw2.jpg'
        },
        {
            title : 'The Principles Of Power',
            description : 'Menjadi sosok yang berkuasa dan berpengaruh adalah keinginan banyak orang. Manusia merasa tidak berdaya ketika mereka tidak memiliki kuasa sehingga mereka akan melakukan apa pun untuk mendapatkan kekuasaan, atau setidaknya menjadi orang yang berpengaruh dan disegani. Kehidupan sehari hari menunjukkan bahwa mereka yang memiliki posisi tinggi cenderung lebih mudah hidupnya. Orang-orang menghormati mereka. Mereka mendapatkan prioritas pertama, serta pelayanan yang jauh lebih istimewa. Siapa yang tidak ingin?',
            cover : 'https://cdn.gramedia.com/uploads/picture_meta/2023/10/7/9rqesfm5bmh4qfaouddufp.jpg'
        },
        {
            title : 'The Magic',
            description : `Lebih dari dua puluh abad, kata-kata dalam sebuah kalimat
suci membuat hampir semua orang yang membacanya
bingung, penasaran, dan menyalahartikannya. Hanya
sangat sedikit orang sepanjang sejarah yang menyadari
bahwa kata-kata tersebut adalah teka teki, dan begitu kita
memecahkan teka teki itu—begitu misterinya terkuak—
sebuah dunia baru akan tergelar di depan mata kita.
Dalam The Magic, Rhonda Byrne mengungkapkan ke
dunia suatu pengetahuan yang mampu mengubah hidup
kita. Kemudian, dalam latihan luar biasa selama 28 hari,
ia mengajarkan cara menerapkan pengetahuan ini dalam
hidup kita sehari-hari.
Siapa pun Anda, di mana pun Anda berada, apa pun
lingkungan Anda sekarang, The Magic akan mengubah
total hidup Anda!`,
            cover : 'https://cdn.gramedia.com/uploads/items/magic.jpg'
        },
        {
            title : 'The Psychology of Money Edisi Revisi',
            description : `kesuksesan dalam mengelola uang tidak selalu tentang apa yang Anda ketahui. Ini tentang bagaimana Anda berperilaku. Dan perilaku sulit untuk diajarkan, bahkan kepada orang yang sangat pintar sekalipun. Seorang genius yang kehilangan kendali atas emosinya bisa mengalami bencana keuangan. Sebaliknya, orang biasa tanpa pendidikan finansial bisa kaya jika mereka punya sejumlah keahlian terkait perilaku yang tak berhubungan dengan ukuran kecerdasan formal.
                           Uang―investasi, keuangan pribadi, dan keputusan bisnis―biasanya diajarkan sebagai bidang berbasis matematika, dengan data dan rumus memberi tahu kita apa yang harus dilakukan. Namun di dunia nyata, orang tidak membuat keputusan finansial di spreadsheet. Mereka membuatnya di meja makan, atau di ruang rapat, di mana sejarah pribadi, pandangan unik Anda tentang dunia, ego, kebanggaan, pemasaran, dan berbagai insentif bercampur.`,
            cover : 'https://cdn.gramedia.com/uploads/picture_meta/2024/1/20/qvjtc65vbzmexfegzrgs7u.jpg'
        },
//         {
//             title : 'Life is Yours : Hidup ini Milikmu, bukan Milik Orang Lain',
//             description : `
//             Tidak apa-apa untuk tidak tahu banyak hal, karena kamu manusia biasa, bukan Google. Tidak apa-apa untuk mengalami hal-hal yang memalukan, tidak apa-apa untuk belum jadi siapa-siapa di usiamu yang sudah seperempat abad ini. Tidak apa-apa untuk membenci yang disukai orang lain, dan menyukai yang dibenci oleh orang lain.
//             Hidup bukan atas apa yang dikatakan orang, bukan pula atas apa yang ada di genggaman tangan orang. Life is yours, hidup ini milikmu. Kamu yang berhak menentukan kemana kakimu melangkah, kamu yang berhak milih, mau jalan cepat ataukah lambat. Selagi kamu bahagia, kamu pasti akan menikmatinya
//             `,
//             cover : 'https://cdn.gramedia.com/uploads/picture_meta/2024/3/2/hpdxelhh4vxrpicnrdtrws.jpg'
//         },
//         {
//             title : 'AI Copywriting : Copywriting Tanpa Ribet dan Pusing',
//             description : `Copywriter pada era digital saat ini, dihadapkan pada peluang baru yang ditawarkan oleh kecerdasan buatan atau yang familiar disebut AI. Dalam buku ini, penulis menggali lebih dalam mengenai AI copywriting dan bagaimana teknologi ini memengaruhi profesi copywriter pada era digital. Dengan penjelasan yang ringan, pembaca akan memahami konsep dasar AI copywriting dan bagaimana algoritma-algoritma cerdas dapat digunakan untuk menghasilkan konten yang menarik dan efektif. Buku ini juga mengungkap peluang baru yang ada di dunia AI copywriting, seperti meningkatkan efisiensi penulisan, personalisasi konten, dan meningkatkan interaksi dengan audiens.
// Buku ini membahas secara rinci mengenai tantangan yang dihadapi oleh copywriter dalam mengadaptasi teknologi AI copywriting, termasuk kekhawatiran akan penggantian pekerja manusia oleh AI. Penulis memberikan panduan praktis tentang bagaimana menghadapi tantangan ini dan tetap menjadi copywriter yang kompetitif pada era digital. Buku ini dapat menjadi panduan yang komprehensif bagi para copywriter, pemasar, dan pemilik bisnis yang ingin memanfaatkan kecerdasan buatan untuk mendukung pekerjaannya. Dengan pemahaman yang mendalam tentang AI copywriting, para pembaca akan dapat memanfaatkan teknologi ini secara efektif dan tetap relevan pada era digital yang terus berkembang.`,
//             cover : 'https://cdn.gramedia.com/uploads/picture_meta/2023/12/6/ydpmosjkb6sha6jxvi3y3z.jpg'
//         },

    ]
        // /v1/books

}