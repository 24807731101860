import {Button, Card, CardImg, Col, Container, Image, Row} from "react-bootstrap";
import Palette from "../../utils/Palette.";
import Book from "../../daos/Book";
import MainButton from "../reusable/MainButton";
import ChapterList from "../reusable/ChapterList";
import {useEffect, useState} from "react";
import {Link, useOutletContext, useParams} from "react-router-dom";
import {Icon} from "@iconify/react";
import {useOrganizationData} from "../../utils/OrganizationDataProvider";
import BookUserDAO from "../../daos/BookUserDAO";
import swal from "../reusable/CustomSweetAlert";
import {isMobile} from 'react-device-detect';


export default function BookDetailPage() {
    let token = sessionStorage.getItem('token') || localStorage.getItem('token')


    const {primaryColor} = useOrganizationData()

    const {id} = useParams();
    const [chapters, setChapters] = useState([])

    const [details, setDetails] = useState([])
    const [rating, setRating] = useState({likes: 0, dislikes: 0})
    const [showMore, setShowMore] = useState(false)

    const [windowWidth, setWindowWidth] = useOutletContext()

    const handleShareMobile = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Title of the shared content',
                    text: 'Description of the shared content',
                    url: 'https://example.com', // URL you want to share
                });
            } else {
                throw new Error('Web Share API not supported');
            }
        } catch (error) {
            console.error('Error sharing:', error);
            // Fallback mechanism
            return await swal.fire({
                text: "Fitur sedang dalam perbaikan!",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    };

    const handleShareDesktop = async () => {
        navigator.clipboard.writeText(window.location.href)
        return await swal.fire({
            title: "Link berhasil disalin!",
            icon: 'success',
            confirmButtonText: 'Okay'
        })
    };

    const getRatings = async () => {
        try {
            const resultLikesAndDislikes = await Book.getLikesAndDislikes(id)
            setRating(resultLikesAndDislikes)

        } catch (e) {
            console.log(e)
        }
    }

    const initialize = async () => {
        try {
            const result = await Book.getByID(id)
            const resultChapter = await Book.getChapters(id)
            getRatings()
            setChapters(resultChapter)
            setDetails(result)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        initialize()
        getUserBookData()
        getReaderCount()
    }, []);

    const handleAddToFavorite = async () => {
        if (!token) return await swal.fire({
            text: "Harap login sebelum tambah buku ke daftar saya!",
            icon: 'error',
            confirmButtonText: 'Okay'
        })
        try {
            let result = await BookUserDAO.setFavorite(id)
            getHistory()
        } catch (e) {
            console.log(e)
        }
        console.log('Handle add to favorite here')
    }

    const handleLike = async () => {
        if (token) {
            try {
                let result = await BookUserDAO.like(id, {liked: 1});
                getUserBookData()
                getRatings()
            } catch (e) {
                console.log(e)
            }
        } else {
            return await swal.fire({
                title: 'Login terlebih dahulu!',
                text: "Login untuk memberi rating!",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }

    const handleDislike = async () => {
        if (token) {
            try {
                let result = await BookUserDAO.like(id, {liked: -1});
                getUserBookData()
                getRatings()
            } catch (e) {
                console.log(e)
            }
        } else {
            return await swal.fire({
                title: 'Login terlebih dahulu!',
                text: "Login untuk memberi rating!",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }

    const [data, setData] = useState({})

    const getUserBookData = async () => {
        if (token) {
            try {
                let result = await BookUserDAO.getByUserIdAndBookId(id)
                setData(result)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const [numOfReaders, setNumOfReaders] = useState(0)

    const getReaderCount = async () => {
        try{
            let result = await BookUserDAO.countBookReaderByBookId(id)
            setNumOfReaders(result.num_of_readers)
        }catch (e) {
            console.log(e)
        }
    }

    const [latestChapter, setLatestChapter] = useState({})
    const [progress, setProgress] = useState({})

    const getHistory = async () => {
        if (token) {
            try {
                let result = await BookUserDAO.getByUserIdAndBookId(parseInt(id))
                console.log('progress here', result)
                setProgress(result)

                let latestChapterTemp = chapters.find((chapter) => chapter.chapter_num === result.progress)
                setLatestChapter(latestChapterTemp)
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        getHistory()
    }, [chapters]);


    return (
        <>
            <Container className={'mt-5'} style={{marginBottom: isMobile ? 70 : 0}}>
                <Row className={'py-2'}>
                    <Col lg={6}>
                        <Row>
                            <Col lg={3} style={{justifyContent: "center", display: "flex"}}>
                                <Image src={details?.cover} className={'img-thumbnail'} width={"200px"}
                                       style={{maxWidth: "150px", height: "auto"}}></Image>
                            </Col>
                            <Col lg={9} md={12}>
                                <h3 style={{
                                    width: "100%",
                                    marginTop: isMobile ? 10 : null,
                                    textAlign: windowWidth > 576 ? "left" : "center"
                                }}>{details?.title}</h3>
                                <p style={{width: "100%", textAlign: windowWidth > 576 ? "left" : "center"}}>Oleh
                                    : {details?.author}</p>
                                <Row className={'mt-5'}>
                                    <Col xs={6}>
                                        <p style={{
                                            fontSize: '0.9em',
                                            lineHeight: 0,
                                            width: "100%",
                                            textAlign: windowWidth > 576 ? "left" : "center"
                                        }}>Jumlah dibaca</p>
                                        <p style={{
                                            width: "100%",
                                            textAlign: windowWidth > 576 ? "left" : "center"
                                        }}>{numOfReaders} orang</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p style={{
                                            fontSize: '0.9em',
                                            lineHeight: 0,
                                            width: "100%",
                                            textAlign: windowWidth > 576 ? "left" : "center"
                                        }}>Waktu Baca</p>
                                        <p style={{width: "100%", textAlign: windowWidth > 576 ? "left" : "center"}}>
                                            {
                                                details?.estimated_read_time_in_hours >= 1 ?
                                                details?.estimated_read_time_in_hours.toFixed(2) + ' jam' :
                                                    (details?.estimated_read_time_in_hours * 60).toFixed(0) + ' menit'
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                <Row className={'mt-4'}>
                                    <Col lg={6} className="mb-2">
                                        <Link to={
                                            latestChapter && latestChapter.book_id && latestChapter.chapter_num ?
                                                `/read/${latestChapter?.book_id}/${latestChapter?.chapter_num}`
                                                :
                                                `/read/${id}/1`
                                        } state={{chapters: chapters}}>
                                            <div className="d-grid gap-2">
                                                <MainButton icon={'mi:book'} color={'PRIMARY'}
                                                            text={progress ? progress.progress > 0 ? 'Lanjut Baca' : 'Baca' : 'Baca'}/>
                                            </div>
                                        </Link>

                                    </Col>
                                    <Col lg={6}>
                                        <div className="d-grid gap-2">
                                            <MainButton
                                                icon={`ic:baseline-${!progress?.favorite ? 'plus' : 'check'}`}
                                                color={'SECONDARY'}
                                                onClick={handleAddToFavorite}
                                                text={'Daftar Saya'}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row className={'mt-4'}>
                            <Col lg={12}>
                                <p style={{textAlign: 'justify'}}>
                                    {details?.description?.length > 300 ? `${showMore ? details?.description : details?.description.substring(0, 300)}...` : details?.description}
                                </p>
                            </Col>
                            {
                                details?.description?.length > 200 && <Col lg={12}>
                                    <a onClick={() => {
                                        setShowMore(!showMore)
                                    }} style={{color: Palette.THEME_PRIMARY, textDecoration: 'none'}}
                                       href={'javascript:;'}>{showMore ? 'Sembunyikan' : 'Selengkapnya'}</a>
                                </Col>
                            }
                        </Row>
                        <div className={'d-block mt-5'}>
                            <div className={'d-flex'} style={{
                                gap: 50,
                                backgroundColor: '',
                                display: 'flex',
                                justifyContent: isMobile ? 'center' : null
                            }}>
                                <div>
                                    <Button onClick={handleLike} variant={'link'}
                                            style={{color: primaryColor, textDecoration: 'none'}}>
                                        <div>
                                            <small style={{}}>{rating.likes}</small>
                                        </div>
                                        <Icon style={{}} width={30} height={30}
                                              icon={data?.liked && data.liked === 1 ? "mdi:like" : "mdi:like-outline"}/>
                                        <div>
                                            <small style={{}}>Suka</small>
                                        </div>

                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={handleDislike} variant={'link'}
                                            style={{color: primaryColor, textDecoration: 'none'}}>
                                        <div>
                                            <small style={{}}>{rating.dislikes}</small>
                                        </div>
                                        <Icon style={{}} width={30} height={30}
                                              icon={data?.liked && data.liked === -1 ? "mdi:dislike" : "mdi:dislike-outline"}/>
                                        <div>
                                            <small style={{}}>Tidak Suka</small>
                                        </div>
                                    </Button>
                                </div>
                                <div>

                                    <Button onClick={isMobile ? handleShareMobile : handleShareDesktop} variant={'link'}
                                            style={{color: primaryColor, textDecoration: 'none'}}>
                                        <div>
                                            <small style={{opacity: 0}}>{rating.dislikes}</small>
                                        </div>
                                        <Icon style={{}} width={30} height={30}
                                              icon="material-symbols:share-outline"/>
                                        <div>
                                            <small style={{}}>Bagikan</small>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} style={{marginTop: 16, padding: 16}}>
                        <h4 >Bagian</h4>
                        <ChapterList chapters={chapters} progress={progress}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}