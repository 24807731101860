import logo from './logo.svg';
import './App.css';
import React, {useState, useEffect} from 'react'
import Palette from './utils/Palette.';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MenuLayout from './components/layouts/MenuLayout';
import ProfilePage from './components/pages/ProfilePage';
import HomePage from './components/pages/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import BookListByCategory from './components/pages/BookListByCategory';
import BookDetailPage from "./components/pages/BookDetailPage";
import BookReadPageInfiniteScrollMode from "./components/pages/BookReadPageInfiniteScrollMode";
import SearchPage from './components/pages/SearchPage';
import {OrganizationDataProvider, useOrganizationData} from './utils/OrganizationDataProvider';
import LoginPage from './components/pages/LoginPage';
import ScanResultPage from './components/pages/ScanResultPage';
import SignUpPage from './components/pages/SignUpPage';
import PleaseScan from './components/pages/PleaseScan';
import SplashScreenPage from './components/pages/SplashScreenPage';
import CodeExpired from "./components/pages/CodeExpired";
import BookReadPageButtonMode from './components/pages/BookReadPageButtonMode'
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import CodeIncorrect from "./components/pages/CodeIncorrect";
import SettingsPage from "./components/pages/SettingsPage";
import MaxBookErrorPage from "./components/pages/MaxBookErrorPage";
import NotFound from "./components/pages/NotFoundPage";


export default function (props) {
    const [font, setFont] = useState(localStorage.getItem('font') || 'Arial')
    const [fontSize, setFontSize] = useState(parseFloat(localStorage.font_size) || 0)
    const [mode, setMode] = useState(localStorage.getItem('mode') || "DARK")

    useEffect(() => {
        localStorage.font = font
        sessionStorage.font = font
    }, [fontSize, font]);

    useEffect(() => {
        localStorage.font_size = fontSize
        sessionStorage.font_size = fontSize
    }, [fontSize]);

    useEffect(() => {
        console.log('changed', mode)
        localStorage.mode = mode
        sessionStorage.mode = mode
    }, [mode]);

    return <OrganizationDataProvider
        mode={mode}
        setMode={setMode}
    >
        <BrowserRouter>
            <Routes>
                <Route path="" element={
                    <MenuLayout
                        font={font}
                        setFont={setFont}
                        setFontSize={setFontSize}
                        fontSize={fontSize}
                        mode={mode}
                        setMode={setMode}
                    />
                }>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="book/:id" element={<BookDetailPage/>}/>
                    <Route path="books/:cat/:catId" element={<BookListByCategory/>}/>
                    <Route path="search" element={<SearchPage/>}/>
                    <Route path="search/:search" element={<SearchPage/>}/>
                    <Route
                        path="read/:bookId/:chapterNum"
                        element={
                            <BookReadPageButtonMode
                                font={font}
                                setFont={setFont}
                                setFontSize={setFontSize}
                                fontSize={fontSize}
                                mode={mode}
                                setMode={setMode}
                            />
                        }
                    />
                    <Route path="read/infinite-scroll/:bookId/:chapterNum" element={<BookReadPageInfiniteScrollMode/>}/>
                    <Route path="profile" element={<ProfilePage/>}/>
                    <Route path="forgot-password" element={<ForgotPassword/>}/>
                    <Route path="reset-password" element={<ResetPassword/>}/>
                    <Route path="login" element={<LoginPage/>}/>
                    <Route path="signup" element={<SignUpPage/>}/>
                    <Route path="settings" element={<SettingsPage/>}/>
                </Route>

                <Route path="/scan/:code" element={<ScanResultPage/>}/>
                <Route path="pleaseScan" element={<PleaseScan/>}/>
                <Route path="splash" element={<SplashScreenPage/>}/>
                <Route path="expired" element={<CodeExpired/>}/>
                <Route path="incorrect" element={<CodeIncorrect/>}/>
                <Route path="book-max-capacity" element={<MaxBookErrorPage/>}/>
                <Route path="*" element={<NotFound/>}/>

            </Routes>
        </BrowserRouter>
    </OrganizationDataProvider>
}


